import { TabPane } from "reactstrap";

const Engineer = ({ tabId, activeTab, toggleTab }) => {
  return (
    <TabPane tabId={tabId}>
      <div>Engineer</div>
    </TabPane>
  );
};

export default Engineer;
