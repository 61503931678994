import React, { useEffect, useState } from "react";

import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfilePlaceholder from "../../assets/images/ProfilePlaceholder.png";
import {
  Footer,
  Header,
  ModalLayout,
  PrimaryButton,
} from "../../components/common";
import Error from "../../components/common/FormControls/Error";
import Label from "../../components/common/FormControls/Label";
import PasswordField from "../../components/common/FormControls/PasswordField";
import RadioField from "../../components/common/FormControls/RadioField";
import TextField from "../../components/common/FormControls/TextField";
import { AppRoutes } from "../../components/constants";
import { VENDOR_LOGIN } from "../../store/apis/actionTypes";
import LocalStorageConstants from "../../utils/storage/local-storage/local-storage-constants";
import LocalStorageUtils from "../../utils/storage/local-storage/local-storage-utils";
import UtilityFunc from "../../utils/utility-func";

const initialState = {
  username: `praveenyadav1007+a1@gmail.com`,
  password: `Praveen@wdse23`,
  userCategory: `P`,
};

const userTypes = {
  Customer: `C`,
  Vendor: `P`,
};

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const loginResponse = useSelector((state) => state?.Login);

  useEffect(() => {
    if (loginResponse?.data?.status === 200) {
      const tokenData = {
        token: `Bearer ${loginResponse?.data?.headers?.authorization}`,
        data: loginResponse?.data?.data,
      };

      LocalStorageUtils.saveToLocalStorage(
        LocalStorageConstants.AUTH_USER,
        tokenData
      );

      if (loginResponse?.data?.data?.userCategory === `C`) {
        navigate(AppRoutes?.CUSTOMER_REQUESTOR_DASHBOARD);
        navigate(0);
      } else if (loginResponse?.data?.data?.userCategory === `P`) {
        navigate(AppRoutes?.VENDOR_BIDDER_DASHBOARD);
        navigate(0);
      }
    }
  }, [loginResponse]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case "username":
        updatedState = {
          ...state,
          username: value,
        };
        break;

      case "password":
        updatedState = {
          ...state,
          password: value,
        };
        break;

      case "userCategory":
        updatedState = {
          ...state,
          userCategory: value,
        };
        break;

      default:
        return;
    }
    setState(updatedState);
  };

  const validationCheck = (data, flag) => {
    if (
      data?.username !== `` &&
      data?.password !== `` &&
      UtilityFunc.isValidEmail(data?.username) &&
      UtilityFunc.isValidPassword(data?.password)
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(
        flag === `submissionCheck` ? true : isValidationShow ? true : false
      );
      return false;
    }
  };

  const submitHandler = () => {
    if (validationCheck(state, `submissionCheck`)) {
      dispatch({
        type: VENDOR_LOGIN,
        payload: {
          data: {
            ...state,
          },
        },
      });
    }
  };

  return (
    <>
      <Header />
      <section className="sectionMain">
        <div className="loginWrapper">
          <div className="formContainer">
            <div className="loginPlaceholderContainer">
              <img src={ProfilePlaceholder} alt="ProfilePlaceholder" />
            </div>
            <h2 className="inputContainerFull loginHeading">
              Login to your account
            </h2>

            <div className="inputContainer inputContainerFull">
              <Label showRequired={true}>Enter email</Label>
              <TextField
                name="username"
                value={state?.username}
                onChange={onChangeHandler}
              />

              <Error
                show={
                  isValidationShow &&
                  (state?.username === `` ||
                    (state?.username !== `` &&
                      !UtilityFunc.isValidEmail(state?.username)))
                }
                message="Kindly enter valid email"
              />
            </div>

            <div className="inputContainer inputContainerFull">
              <Label showRequired={true}>Password</Label>
              <PasswordField
                name="password"
                value={state?.password}
                onChange={onChangeHandler}
              />

              <Error
                show={
                  isValidationShow &&
                  (state?.password === `` ||
                    (state?.password !== `` &&
                      !UtilityFunc.isValidPassword(state?.password)))
                }
                message="Kindly enter valid password"
              />
            </div>

            <div className="radioContainer registrationContainer">
              <RadioField
                onChange={onChangeHandler}
                id="2"
                isSelected={state?.userCategory === userTypes?.Vendor}
                label="Vendor"
                value={userTypes?.Vendor}
                name="userCategory"
              />
              <RadioField
                onChange={onChangeHandler}
                id="1"
                isSelected={state?.userCategory === userTypes?.Customer}
                label="Customer"
                value={userTypes?.Customer}
                name="userCategory"
              />
            </div>

            <div className="inputContainerFull forgetPassword">
              <span>Forget Password?</span>
            </div>
          </div>

          <div className="buttonContainer">
            <PrimaryButton name={`Login`} onClick={submitHandler} />
          </div>
        </div>
      </section>

      <Footer />

      {false && (
        <ModalLayout>
          <ModalHeader toggle={() => alert("Closing")}>Modal title</ModalHeader>
          <ModalBody>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </ModalBody>

          <ModalFooter>
            <Button color="primary">Do Something</Button>
            <Button
              color="secondary"
              // onClick={toggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalLayout>
      )}
    </>
  );
}
