import { Col, Row, TabPane } from "reactstrap";
import {
  DateField,
  Label,
  RadioField,
  SelectField,
  TextField,
} from "../../../../../../../components/common/FormControls";
import Tooltip from "../../../../../../../utils/tooltip/tooltip-utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_VENDOR_BUSINESS_INFO,
  CREATE_VENDOR_BUSINESS_INFO_EMPTY,
  GET_COUNTRIES,
  GET_VENDOR_BUSINESS_INFO,
  GET_VENDOR_BUSINESS_INFO_EMPTY,
} from "../../../../../../../store/apis/actionTypes";
import ToastUtils from "../../../../../../../utils/toast/toast-utils";
import RegexUtils from "../../../../../../../utils/regex/regex-utils";

const BusinessInformation = ({
  tabId,
  activeTab,
  toggleTab,
  onToggleSection,
  registrationStatus,
}) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [formData, setFormData] = useState({
    ...initialFormData,
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  /* ---------------------------- REDUX STATES ---------------------------- */
  // ADD BUSINESS INFO
  const { createVendorBusinessInfo, createVendorBusinessInfoLoading } =
    useSelector((state) => ({
      createVendorBusinessInfo: state?.CreateVendorBusinessInfo?.data,
      createVendorBusinessInfoLoading: state?.CreateVendorBusinessInfo?.loading,
    }));

  // GET BUSINESS INFO
  const { getVendorBusinessInfo, getVendorBusinessInfoLoading } = useSelector(
    (state) => ({
      getVendorBusinessInfo: state?.GetVendorBusinessInfo?.data,
      getVendorBusinessInfoLoading: state?.GetVendorBusinessInfo?.loading,
    })
  );

  // COUNTRIES
  const { countries, countriesLoading } = useSelector((state) => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // CITIES
  const { cities, citiesLoading } = useSelector((state) => ({
    cities: state?.Cities?.data,
    citiesLoading: state?.Cities?.loading,
  }));

  useEffect(() => {
    if (createVendorBusinessInfo !== null) {
      ToastUtils.showSuccessToast("Business Info added successfully");
      onToggleSection(3);
      dispatch({
        type: CREATE_VENDOR_BUSINESS_INFO_EMPTY,
      });
    }
  }, [createVendorBusinessInfo]);

  useEffect(() => {
    if (registrationStatus?.isBusinessDetailsAdded && tabId === activeTab) {
      dispatch({
        type: GET_VENDOR_BUSINESS_INFO,
      });
    } else {
      dispatch({
        type: GET_VENDOR_BUSINESS_INFO_EMPTY,
      });
      setFormData({ ...initialFormData });
    }
  }, [tabId, activeTab]);

  useEffect(() => {
    if (getVendorBusinessInfo !== null) {
      setFormData({
        ...initialFormData,
      });
    }
  }, [getVendorBusinessInfo]);

  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRIES,
      });
    }
  }, []);

  // Single comprehensive validation method
  const validateField = (name, value) => {
    const newErrors = { ...errors };
    const newTouched = { ...touched, [name]: true };

    // Validation logic for different fields
    switch (name) {
      case "name":
        if (!value || value.trim() === "") {
          newErrors.name = "Name is required";
        } else if (value.length < 2) {
          newErrors.name = "Name must be at least 2 characters";
        } else {
          delete newErrors.name;
        }
        break;

      case "emailAddress":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          newErrors.emailAddress = "Email Address is required";
        } else if (!emailRegex.test(value)) {
          newErrors.emailAddress = "Invalid email format";
        } else {
          delete newErrors.emailAddress;
        }
        break;

      case "mobileNumber":
        if (!value.trim()) {
          newErrors.mobileNumber = "This field is required";
        } else if (value.length < 6 || value.length > 13) {
          newErrors.mobileNumber = "Should be between 6 to 13 digits";
        } else {
          delete newErrors.mobileNumber;
        }
        break;

      case "password":
        if (!value) {
          newErrors.password = "Password is required";
        } else if (!RegexUtils.passwordRegex.test(value)) {
          newErrors.password = "Invalid password format";
        } else {
          delete newErrors.password;
        }
        break;

      case "rePassword":
        if (!value) {
          newErrors.password = "Confirm Password is required";
        } else if (!RegexUtils.passwordRegex.test(value)) {
          newErrors.password = "Invalid password format";
        } else {
          delete newErrors.password;
        }
        break;

      default:
        break;
    }

    // Update errors and touched states
    setErrors(newErrors);
    setTouched(newTouched);

    // Return whether the form is valid
    return Object.keys(newErrors).length === 0;
  };

  // Submit handler
  const handleSubmit = () => {
    // Mark all fields as touched
    const allTouched = {
      name: true,
      emailAddress: true,
      mobileNumber: true,
      password: true,
      rePassword: true,
    };
    setTouched(allTouched);

    // Validate all required fields
    const requiredFields = [
      "name",
      "emailAddress",
      "mobileNumber",
      "password",
      "rePassword",
    ];

    let newErrors = {};

    // Validate each required field
    requiredFields.forEach((field) => {
      if (
        !formData[field] ||
        (typeof formData[field] === "string" && !formData[field].trim())
      ) {
        newErrors[field] = "This field is required";
      } else {
        // Additional field-specific validations
      }
    });

    // Set the errors state
    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;

    if (isValid) {
      // Prepare payload
      const payload = {
        name: formData.name,
        emailAddress: formData.emailAddress,
        phoneCode: formData.phoneCode,
        mobileNumber: formData.mobileNumber,
        isAvailableOnWhatsApp: formData.isAvailableOnWhatsApp,
        availableOn: formData.availableOn,
        password: formData.password,
        rePassword: formData.rePassword,
      };

      console.log("submitting", payload);

      dispatch({
        type: CREATE_VENDOR_BUSINESS_INFO,
        payload: {
          data: payload,
        },
      });
    }
  };

  // Blur handler to mark fields as touched
  const handleBlur = (name) => {
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    // Validate the field when it loses focus
    validateField(name, formData[name]);
  };

  const handleMobileChange = (value, data) => {
    setFormData((prev) => ({
      ...prev,
      phoneCode: `+${data?.dialCode}`,
      mobileNumber: value.slice(data?.dialCode?.length),
    }));
    validateField("mobileNumber", value.slice(data?.dialCode?.length));
  };

  // Input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validate the specific field
    validateField(name, value);
  };

  return (
    <TabPane tabId={tabId}>
      <Row>
        <Col lg={12} className="my-2">
          <Label>
            My company is registered?{" "}
            <Tooltip name="is_com_reg">
              To check whether your company is registered.
            </Tooltip>
          </Label>
          <div className={"radioContainer"}>
            <RadioField
              id="company-onWhatsapp-yes"
              label="Yes"
              value={true}
              // changed={radioChangeHandler}
              // isSelected={state?.isAvailableOnWhatsApp === true}
            />
            <RadioField
              id="company-onWhatsapp-no"
              label="No"
              value={false}
              // changed={radioChangeHandler}
              // isSelected={state?.isAvailableOnWhatsApp === false}
            />
          </div>
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Registration No.{" "}
            <Tooltip name="is_reg_no">
              Registration no. of your company given by the associated country.
            </Tooltip>
          </Label>
          <TextField
            name="vesselName"
            // disabled={isReviewState}
            // value={state?.vesselDetail?.name}
            // onChange={handleChange}
          />
          {/* <Error
            show={isValidationPass && !state?.vesselDetail?.name}
            message="Please Enter Vessel Name"
          /> */}
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Registered On{" "}
            <Tooltip name="is_reg_on">
              Date on which your company is registered.
            </Tooltip>
          </Label>
          <DateField
            name="eta"
            // disabled={isReviewState}
            // onChange={(time) =>
            //   setState((prev) => ({
            //     ...prev,
            //     serviceRequestAt: {
            //       ...prev?.serviceRequestAt,
            //       eta: time,
            //     },
            //   }))
            // }
            options={{
              minDate: "today",
              enableTime: true,
              dateFormat: "Y-m-d H:i",
            }}
          />
          {/* <Error
            show={isValidationPass && !state?.serviceRequestAt?.eta}
            message="Please Select E.T.A (Local Time)"
          /> */}
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Country of Registration{" "}
            <Tooltip name="is_country_reg">
              Country in which your compay is registered.
            </Tooltip>
          </Label>
          <SelectField
            name="vesselTypeId"
            // isDisabled={isReviewState}
            // options={
            //   vesselTypes?.result?.map((vesselType) => ({
            //     value: vesselType?.id,
            //     label: vesselType?.name,
            //     data: vesselType,
            //   })) ?? []
            // }
            // onChange={(data) => onSelectHandler(data, `vesselTypeId`)}
          />
          {/* <Error
                    show={isValidationPass && !state?.vesselDetail?.vesselTypeId}
                    message="Please Select Vessel Type"
                  /> */}
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            City of Registration{" "}
            <Tooltip name="is_city_reg">
              City in which your compay is registered.
            </Tooltip>
          </Label>
          <SelectField
            name="vesselTypeId"
            // isDisabled={isReviewState}
            // options={
            //   vesselTypes?.result?.map((vesselType) => ({
            //     value: vesselType?.id,
            //     label: vesselType?.name,
            //     data: vesselType,
            //   })) ?? []
            // }
            // onChange={(data) => onSelectHandler(data, `vesselTypeId`)}
          />
          {/* <Error
                    show={isValidationPass && !state?.vesselDetail?.vesselTypeId}
                    message="Please Select Vessel Type"
                  /> */}
        </Col>

        <Col lg={12} className="my-2">
          <Label>Is your Company established in last five years? </Label>
          <div className={"radioContainer"}>
            <RadioField
              id="company-onWhatsapp-yes"
              label="Yes"
              value={true}
              // changed={radioChangeHandler}
              // isSelected={state?.isAvailableOnWhatsApp === true}
            />
            <RadioField
              id="company-onWhatsapp-no"
              label="No"
              value={false}
              // changed={radioChangeHandler}
              // isSelected={state?.isAvailableOnWhatsApp === false}
            />
          </div>
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Established Date{" "}
            <Tooltip name="is_com_est">
              If your company is not registered, we need this information.
            </Tooltip>
          </Label>
          <DateField
            name="eta"
            // disabled={isReviewState}
            // onChange={(time) =>
            //   setState((prev) => ({
            //     ...prev,
            //     serviceRequestAt: {
            //       ...prev?.serviceRequestAt,
            //       eta: time,
            //     },
            //   }))
            // }
            options={{
              minDate: "today",
              enableTime: true,
              dateFormat: "Y-m-d H:i",
            }}
          />
          {/* <Error
            show={isValidationPass && !state?.serviceRequestAt?.eta}
            message="Please Select E.T.A (Local Time)"
          /> */}
        </Col>
      </Row>
    </TabPane>
  );
};

export default BusinessInformation;

const initialFormData = {
  registrationCountryId: null,
  registrationCityId: null,
  registrationNumber: "",
  registrationDate: "",
  isRegistered: true,
  isEstablishedLastFiveYear: false,
  logoPath: "",
  companyEstablishedOn: "",
  logoUrl: "",
};
