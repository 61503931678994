import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_ERROR,
} from "../../../actionTypes.js";

function* getInvoiceDetails() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/invoice/v1/detail`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_INVOICE_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_INVOICE_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_INVOICE_DETAILS_ERROR, payload: error });
  }
}

export function* watchInvoicedetails() {
  yield takeEvery(GET_INVOICE_DETAILS, getInvoiceDetails);
}

function* getInvoiceDetailsSaga() {
  yield all([fork(watchInvoicedetails)]);
}

export default getInvoiceDetailsSaga;
