import React from "react";

const Navigation = ({ activeTab, toggleTab, registrationStatus }) => {
  /* ---------------------------- COMPLETTION CHECK HANDLER ---------------------------- */
  const checkCompleteValidation = (id, name) => {
    let completionFlag = false;
    if (name === `Bidder` && registrationStatus?.isBidderAdded) {
      completionFlag = true;
    } else if (name === `Approver` && registrationStatus?.isApproverAdded) {
      completionFlag = true;
    } else if (name === `Engineer` && registrationStatus?.isEngineerAdded) {
      completionFlag = true;
    }

    if (completionFlag) {
      return (
        <div className={"doneImgContainer"}>
          {/* <Image src={doneImg} width="50px" height="50px" alt="/" /> */}
        </div>
      );
    }
  };

  return (
    <nav className={"navTabs"}>
      {navMenus.map(({ id, text }) => (
        <React.Fragment key={id}>
          <div
            className={`${"navItem"} ${activeTab === id && "active"}`}
            onClick={() => {
              toggleTab(id);
            }}
          >
            {checkCompleteValidation(id, text)}
            <div className={`${"navLink"}`}>{text}</div>
          </div>
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Navigation;

const navMenus = [
  {
    id: 4,
    text: "Bidder",
  },
  {
    id: 5,
    text: "Approver",
  },
  {
    id: 6,
    text: "Engineer",
  },
];
