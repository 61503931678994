import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DashboardLoader, Table } from "../../../../../../components/common";
import { BidStatuses } from "../../../../../../enums/utils-enums";
import { useCountdown } from "../../../../../../hooks/useCountdown";
import {
  GET_ALL_BIDS,
  GET_ALL_BIDS_EMPTY,
} from "../../../../../../store/apis/actionTypes";
import DateUtils from "../../../../../../utils/date-utils";

const RejectedBids = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // BIDS
  const { bids, bidsLoading } = useSelector((state) => ({
    bids: state?.GetAllBids?.data,
    bidsLoading: state?.GetAllBids?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_ALL_BIDS,
      payload: {
        params: {
          status: BidStatuses?.REJECTED,
          page: currentPageNumber,
          size: perPageLimit,
        },
      },
    });

    return () => {
      dispatch({
        type: GET_ALL_BIDS_EMPTY,
      });
    };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);

    dispatch({
      type: GET_ALL_BIDS,
      payload: {
        params: {
          status: BidStatuses?.REJECTED,
          page: page,
          size: perPageLimit,
        },
      },
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage, page) => {
    setCurrentPageNumber(page);
    setPerPageLimit(newRowsPerPage);

    dispatch({
      type: GET_ALL_BIDS,
      payload: {
        params: {
          status: BidStatuses?.REJECTED,
          page: page,
          size: newRowsPerPage,
        },
      },
    });
  };

  const handleRowClick = (row) => {};

  return (
    <>
      {bidsLoading && <DashboardLoader />}
      <Table
        data={[
          ...(bids?.result ?? [])?.map((data, index) => ({
            ...data,
            serialNo: `${
              perPageLimit * currentPageNumber - perPageLimit + index + 1
            }.`,
          })),
        ]}
        limit={perPageLimit}
        totalCount={bids?.count}
        columns={columns}
        currentPage={currentPageNumber}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default RejectedBids;

const CountdownTimer = ({ remainingTime }) => {
  const { timeLeft, isExpired } = useCountdown(remainingTime);

  return <span className={isExpired ? "text-danger" : ""}>{timeLeft}</span>;
};

const columns = [
  {
    name: "S.No",
    selector: (row) => <span className="fw-bold">{row?.serialNo}</span>,
    sortable: false,
    width: "90px",
  },
  {
    name: "Request ID",
    selector: (row) => row?.serviceRequestId,
    width: "150px",
  },
  {
    name: "Time Left",
    selector: (row) => <CountdownTimer remainingTime={row?.remainingBidTime} />,
    width: "140px",
  },
  {
    name: "Bid ID",
    selector: (row) => row?.bidId,
    width: "180px",
  },
  {
    name: "Category",
    selector: (row) => row?.equipmentCategoryName,
    width: "150px",
  },
  {
    name: "Vessel",
    selector: (row) => row?.vesselName,
    width: "200px",
  },
  {
    name: "Country",
    selector: (row) => row?.countryName,
    width: "150px",
  },
  {
    name: "ETA",
    selector: (row) => DateUtils?.formatDateObject(row?.serviceRequestOn),
    width: "200px",
  },
  {
    name: "ETD",
    selector: (row) => DateUtils?.formatDateObject(row?.serviceRequestOn),
    width: "200px",
  },
];
