import { Col, Row } from "react-bootstrap";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../../components/common";
import TextValue from "../../../../../components/common/TextValue";
import DashboardLayoutWrapper from "../BidderLayout";

const BidsProposal = () => {
  return (
    <DashboardLayoutWrapper>
      <div className="card request-card p-3">
        <div className="row ">
          <div className="col-md-4">
            <p className="mb-1 fw-bold">Request ID:</p>
            <p className="mb-0">123400000–0122–001</p>
          </div>

          <div className="col-md-4">
            <p className="mb-1 fw-bold">Ship Name:</p>
            <p className="mb-0">Lorem Ipsum</p>
          </div>

          <div className="col-md-4">
            <p className="mb-1 fw-bold">Category:</p>
            <p className="mb-0">Bridge</p>
          </div>
        </div>
      </div>
      <div className="request-card my-4">
        <div className="breakup-header">
          <Row>
            <Col lg="4">
              <div className="  fw-bold">Description</div>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <div className=" fw-bold">Proposed Amount</div>
            </Col>
          </Row>
        </div>

        <div className="bids-proposal-content">
          <Row>
            <Col lg="4">
              <TextValue>Normal Days Normal Hours</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Normal Days OT 1</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Normal Days OT 2</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Weekend Normal Hours</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <TextValue>Weekend OT 1</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue className={"fw-bolder"}>Working Total</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue className={"fw-bolder"}>450</TextValue>
            </Col>
          </Row>
        </div>
      </div>

      {/* Travel Total Section  */}

      <div className="request-card my-4">
        <div className="bids-proposal-content">
          <Row>
            <Col lg="4">
              <TextValue>Air Fare</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Transportation 1</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Transportation 2</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Travel Time Normal Hours</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <TextValue>Travel Time OT1</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Travel Time OT2</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue className={"fw-bolder"}>Travel Total</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue className={"fw-bolder"}>4534</TextValue>
            </Col>
          </Row>
        </div>
      </div>

      {/*  Total Section  */}

      <div className="request-card my-4">
        <div className="bids-proposal-content">
          <Row>
            <Col lg="4">
              <TextValue>Mandatory Spares</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Port Charges </TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Covid related Charges</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Shipyard Charges</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <TextValue>Accommodation</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Service Admin fee</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue>Miscellaneous</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue>450</TextValue>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TextValue className={"fw-bolder"}> Total</TextValue>
            </Col>
            <Col lg="4"></Col>
            <Col lg="4" className="text-center">
              <TextValue className={"fw-bolder"}>4534</TextValue>
            </Col>
          </Row>
        </div>
      </div>
      <div className="request-card my-4">
        <div className="bids-proposal-content">
          <Row>
            <Col lg="3">
              <TextValue className={"fw-bolder"}>Grand Total (A+B+C)</TextValue>
            </Col>
            <Col lg="3">
              <TextValue className={"fw-bolder"}>12,842</TextValue>
            </Col>
            <Col lg="3">
              <TextValue className={"fw-bolder"}>12,842</TextValue>
            </Col>
            <Col lg="3">
              <TextValue className={"fw-bolder"}>12,842</TextValue>
            </Col>
          </Row>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-2 gap-4">
        <SecondaryButton name="Amend" />
        <PrimaryButton name="Proceed" />
      </div>
    </DashboardLayoutWrapper>
  );
};
export default BidsProposal;
