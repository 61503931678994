import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_CUSTOMER_COMPANY_APPROVAL_LEVELS,
  GET_CUSTOMER_COMPANY_APPROVAL_LEVELS_SUCCESS,
  GET_CUSTOMER_COMPANY_APPROVAL_LEVELS_ERROR,
} from "../../../actionTypes.js";

function* createCustomerCompanyApprovalLevel({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/customer/v1/company-level`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: GET_CUSTOMER_COMPANY_APPROVAL_LEVELS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CUSTOMER_COMPANY_APPROVAL_LEVELS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CUSTOMER_COMPANY_APPROVAL_LEVELS_ERROR,
      payload: error,
    });
  }
}

export function* watchCreateCustomerCompanyApprovalLevel() {
  yield takeEvery(
    GET_CUSTOMER_COMPANY_APPROVAL_LEVELS,
    createCustomerCompanyApprovalLevel
  );
}

function* createCustomerCompanyApprovalLevelSaga() {
  yield all([fork(watchCreateCustomerCompanyApprovalLevel)]);
}

export default createCustomerCompanyApprovalLevelSaga;
