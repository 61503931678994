import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  SUCCESS_PAYMENT_DETAILS,
  SUCCESS_PAYMENT_DETAILS_SUCCESS,
  SUCCESS_PAYMENT_DETAILS_ERROR,
} from "../../../actionTypes.js";

function* getSuccessPaymentDetails() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/payment/v1/success`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: SUCCESS_PAYMENT_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: SUCCESS_PAYMENT_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: SUCCESS_PAYMENT_DETAILS_ERROR, payload: error });
  }
}

export function* watchGetSuccessPaymentDetails() {
  yield takeEvery(SUCCESS_PAYMENT_DETAILS, getSuccessPaymentDetails);
}

function* getSuccessPaymentDetailsSaga() {
  yield all([fork(watchGetSuccessPaymentDetails)]);
}

export default getSuccessPaymentDetailsSaga;
