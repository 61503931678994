import React from "react";
import DataTable from "react-data-table-component";

const Table = ({
  data = [],
  columns = [],
  limit = 10,
  currentPage = 1,
  totalCount = 0,
  handlePageChange,
  handleRowsPerPageChange,
  onRowClick,
}) => {
  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      paginationServer
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
      customStyles={customStyles}
      paginationRowsPerPageOptions={[5, 10, 15, 20]}
      paginationTotalRows={totalCount}
      paginationPerPage={limit}
      paginationDefaultPage={currentPage}
      onRowClicked={onRowClick}
    />
  );
};

export default Table;

const customStyles = {
  table: {
    style: {
      border: "1px solid #0000001f",
      borderRadius: "10px",
      overflow: "hidden",
      marginTop: "20px",
      // boxShadow: "2px 4px 8px #00000029",
      // margin: "20px 10px",
    },
  },
  headRow: {
    style: {
      minHeight: "42px",
    },
  },

  headCells: {
    style: {
      backgroundColor: "var(--secondary)",
      fontWeight: "800",
      color: "#333",
      fontSize: "14px",
      padding: "15px 20px",
    },
  },
  rows: {
    style: {
      color: "#333",
      minHeight: "42px",
      cursor: "pointer",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      fontWeight: "400",
      padding: "10px 20px",
    },
  },
  pagination: {
    style: {
      fontSize: "14px",
      color: "#333",
      border: "none",
    },
  },
};
