import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_CUSTOMER_HEAD,
  GET_CUSTOMER_HEAD_SUCCESS,
  GET_CUSTOMER_HEAD_ERROR,
} from "../../../actionTypes.js";

function* getCustomerCompanyHead() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/customer/head/v1/get-head`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_CUSTOMER_HEAD_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CUSTOMER_HEAD_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CUSTOMER_HEAD_ERROR, payload: error });
  }
}

export function* watchGetCustomerCompanyHead() {
  yield takeEvery(GET_CUSTOMER_HEAD, getCustomerCompanyHead);
}

function* getCustomerCompanyHeadSaga() {
  yield all([fork(watchGetCustomerCompanyHead)]);
}

export default getCustomerCompanyHeadSaga;
