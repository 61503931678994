import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GET_REGISTRATION_STATUS } from "../../../../store/apis/actionTypes";
import General from "./Forms/General";
import Team from "./Forms/Team";

const TeamRegistration = ({ currentActiveSection, onToggleSection }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // GET REGISTRATION STATUS
  const { registrationStatus, registrationStatusLoading } = useSelector(
    (state) => ({
      registrationStatus: state?.GetRegistrationStatus?.data,
      registrationStatusLoading: state?.GetRegistrationStatus?.loading,
    })
  );

  useEffect(() => {
    if (registrationStatus === null) {
      dispatch({
        type: GET_REGISTRATION_STATUS,
      });
    }
  }, []);

  if (registrationStatusLoading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      {currentActiveSection === 1 && (
        <General
          onToggleSection={onToggleSection}
          registrationStatus={registrationStatus}
        />
      )}

      {currentActiveSection === 2 && (
        <Team
          onToggleSection={onToggleSection}
          registrationStatus={registrationStatus}
        />
      )}

      {/* {currentActiveSection === 3 && (
        <Services
          onToggleSection={onToggleSection}
          registrationStatus={registrationStatus}
        />
      )} */}

      {/* {currentActiveSection === 4 && (
        <Finances
          onToggleSection={onToggleSection}
          registrationStatus={registrationStatus}
        />
      )} */}

      {/* {currentActiveSection === 5 && (
        <Payment
          onToggleSection={onToggleSection}
          registrationStatus={registrationStatus}
        />
      )} */}
    </React.Fragment>
  );
};

export default TeamRegistration;
