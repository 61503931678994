import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const Navigation = ({ activeTab, toggleTab, registrationStatus }) => {
  /* ---------------------------- COMPLETTION CHECK HANDLER ---------------------------- */
  const checkCompleteValidation = (id, name) => {
    let completionFlag = false;
    if (name === `Company Address` && registrationStatus?.isAddressAdded) {
      completionFlag = true;
    } else if (name === `Company Head` && registrationStatus?.isHeadAdded) {
      completionFlag = true;
    } else if (
      name === `Business Information` &&
      registrationStatus?.isBusinessDetailsAdded
    ) {
      completionFlag = true;
    }

    if (completionFlag) {
      return (
        <div className={"doneImgContainer"}>
          <span
            style={{
              fontSize: "16px",
              // color: "green",
            }}
          >
            <FaCheckCircle />
          </span>
        </div>
      );
    }
  };

  return (
    <nav className={"navTabs"}>
      {navMenus.map(({ id, text }) => (
        <React.Fragment key={id}>
          <div
            className={`${"navItem"} ${activeTab === id ? "active" : ""}`}
            onClick={() => {
              toggleTab(id);
            }}
          >
            {checkCompleteValidation(id, text)}
            <div className={`${"navLink"}`} style={{ marginLeft: "10px" }}>
              {text}
            </div>
          </div>
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Navigation;

const navMenus = [
  {
    id: 1,
    text: "Company Address",
  },
  {
    id: 2,
    text: "Company Head",
  },
  {
    id: 3,
    text: "Business Information",
  },
];
