import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_FLEET_INFO,
  GET_FLEET_INFO_SUCCESS,
  GET_FLEET_INFO_ERROR,
} from "../../../actionTypes.js";

function* getCustomerFleetInfo() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/customer/fleet/v1/get-fleet`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_FLEET_INFO_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_FLEET_INFO_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_FLEET_INFO_ERROR, payload: error });
  }
}

export function* watchGetCustomerFleetInfo() {
  yield takeEvery(GET_FLEET_INFO, getCustomerFleetInfo);
}

function* getCustomerFleetInfoSaga() {
  yield all([fork(watchGetCustomerFleetInfo)]);
}

export default getCustomerFleetInfoSaga;
