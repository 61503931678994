import { useEffect, useRef, useState } from "react";

export const useCountdown = (remainingTime) => {
  const [timeLeft, setTimeLeft] = useState("");
  const startTime = useRef(Date.now());

  useEffect(() => {
    const parseTimeString = (timeStr) => {
      if (!timeStr) return 0;

      const [minutes, seconds] = timeStr.split(":").map(Number);

      return minutes * 60 + seconds;
    };

    const calculateTimeLeft = () => {
      const totalSeconds = parseTimeString(remainingTime);

      const elapsedSeconds = Math.floor(
        (Date.now() - startTime.current) / 1000
      );

      const remainingSeconds = Math.max(0, totalSeconds - elapsedSeconds);

      const formattedMinutes = Math.floor(remainingSeconds / 60);
      const formattedSeconds = remainingSeconds % 60;

      const newTimeLeft = `${
        formattedMinutes < 10 ? "0" + formattedMinutes : formattedMinutes
      }:${formattedSeconds < 10 ? "0" + formattedSeconds : formattedSeconds}`;

      setTimeLeft(newTimeLeft);
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [remainingTime]);

  return {
    timeLeft,
    isExpired: timeLeft === "00:00",
  };
};
