import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
} from "../../../actionTypes.js";

function* createPayment({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/payment/v1/create-order`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_PAYMENT_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_PAYMENT_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_PAYMENT_ERROR, payload: error });
  }
}

export function* watchCreatePaymentHead() {
  yield takeEvery(CREATE_PAYMENT, createPayment);
}

function* createPaymentSaga() {
  yield all([fork(watchCreatePaymentHead)]);
}

export default createPaymentSaga;
