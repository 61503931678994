import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { Col, Row, TabPane } from "reactstrap";
import { PrimaryButton } from "../../../../../../../components/common";
import {
  CheckboxField,
  Error,
  FileUploadField,
  Label,
  MobileField,
  NumberField,
  SelectField,
  TextField,
} from "../../../../../../../components/common/FormControls";
import { authToken, baseUrl } from "../../../../../../../config";
import {
  CREATE_VENDOR_ADDRESS,
  CREATE_VENDOR_ADDRESS_EMPTY,
  GET_CITIES,
  GET_COUNTRIES,
  GET_CURRENCIES,
  GET_STATES,
  GET_VENDOR_ADDRESS,
} from "../../../../../../../store/apis/actionTypes";
import ToastUtils from "../../../../../../../utils/toast/toast-utils";
import Tooltip from "../../../../../../../utils/tooltip/tooltip-utils";
import UtilityFunc from "../../../../../../../utils/utility-func";

const Address = ({ tabId, activeTab, toggleTab, registrationStatus }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [formData, setFormData] = useState({ ...initialFormData });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  /* ---------------------------- REDUX STATES ---------------------------- */
  // ADD ADDRESS
  const { createVendorAddress, createVendorAddressLoading } = useSelector(
    (state) => ({
      createVendorAddress: state?.CreateVendorAddress?.data,
      createVendorAddressLoading: state?.CreateVendorAddress?.loading,
    })
  );

  // GET ADDRESS
  const { getVendorAddress, getVendorAddressLoading } = useSelector(
    (state) => ({
      getVendorAddress: state?.GetVendorAddress?.data,
      getVendorAddressLoading: state?.GetVendorAddress?.loading,
    })
  );

  // COUNTRIES
  const { countries, countriesLoading } = useSelector((state) => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // STATES
  const { states, statesLoading } = useSelector((state) => ({
    states: state?.States?.data,
    statesLoading: state?.States?.loading,
  }));

  // CITIES
  const { cities, citiesLoading } = useSelector((state) => ({
    cities: state?.Cities?.data,
    citiesLoading: state?.Cities?.loading,
  }));

  // CURRENCIES
  const { currencies, currenciesLoading } = useSelector((state) => ({
    currencies: state?.Currencies?.data,
    currenciesLoading: state?.Currencies?.loading,
  }));

  console.log("getVendorAddress", getVendorAddress);

  useEffect(() => {
    if (createVendorAddress !== null) {
      ToastUtils.showSuccessToast("Address added successfully");
      toggleTab(activeTab + 1, "READY_TO_MOVE_ANOTHER_TABS");
      dispatch({
        type: CREATE_VENDOR_ADDRESS_EMPTY,
      });
    }
  }, [createVendorAddress]);

  useEffect(() => {
    if (registrationStatus?.isAddressAdded && tabId === activeTab) {
      dispatch({
        type: GET_VENDOR_ADDRESS,
      });
    } else {
      dispatch({
        type: CREATE_VENDOR_ADDRESS_EMPTY,
      });
      setFormData({ ...initialFormData });
    }
  }, [tabId, activeTab]);

  useEffect(() => {
    if (getVendorAddress !== null) {
      setFormData({
        ...initialFormData,
        companyName: getVendorAddress?.name,
        emailAddress: getVendorAddress?.emailAddress,
        phoneCode: getVendorAddress?.phoneCode,
        mobileNumber: getVendorAddress?.mobileNumber,
        addressLine1: getVendorAddress?.addressLine1,
        addressLine2: getVendorAddress?.addressLine2,
        city: getVendorAddress?.city,
        currency: {
          value: getVendorAddress?.currency?.id,
          label: getVendorAddress?.currency?.name,
        },
        country: {
          value: getVendorAddress?.country?.id,
          label: getVendorAddress?.country?.name,
        },
        state: {
          value: getVendorAddress?.state?.id,
          label: getVendorAddress?.state?.name,
        },
        landmarkCity: {
          value: getVendorAddress?.landmarkCity?.id,
          label: getVendorAddress?.landmarkCity?.name,
        },
        postalCode: getVendorAddress?.postalCode,
      });
    }
  }, [getVendorAddress]);

  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRIES,
      });
    }

    if (currencies === null) {
      dispatch({
        type: GET_CURRENCIES,
      });
    }
  }, []);

  const validateField = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      case "companyName":
        if (!value.trim()) {
          newErrors.companyName = "This field is required";
        } else if (!UtilityFunc.isValidAplha(value)) {
          newErrors.companyName = "Please enter a valid company name";
        } else {
          delete newErrors.companyName;
        }
        break;

      case "emailAddress":
        if (!value.trim()) {
          newErrors.emailAddress = "This field is required";
        } else if (!UtilityFunc.isValidEmail(value)) {
          newErrors.emailAddress = "Please enter a valid email address";
        } else {
          delete newErrors.emailAddress;
        }
        break;

      case "mobileNumber":
        if (!value.trim()) {
          newErrors.mobileNumber = "This field is required";
        } else if (value.length < 6 || value.length > 13) {
          newErrors.mobileNumber = "Should be between 6 to 13 digits";
        } else {
          delete newErrors.mobileNumber;
        }
        break;

      case "currency":
        if (!value) {
          newErrors.currency = "This field is required";
        } else {
          delete newErrors.currency;
        }
        break;

      case "addressLine1":
        if (!value.trim()) {
          newErrors.addressLine1 = "This field is required";
        } else {
          delete newErrors.addressLine1;
        }
        break;

      case "country":
        if (!value) {
          newErrors.country = "This field is required";
        } else {
          delete newErrors.country;
        }
        break;

      case "state":
        if (!value) {
          newErrors.state = "This field is required";
        } else {
          delete newErrors.state;
        }
        break;

      case "city":
        if (!value.trim()) {
          newErrors.city = "This field is required";
        } else if (!UtilityFunc.isValidAplha(value)) {
          newErrors.city = "Please enter a valid city name";
        } else {
          delete newErrors.city;
        }
        break;

      case "landmarkCity":
        if (!value) {
          newErrors.landmarkCity = "This field is required";
        } else {
          delete newErrors.landmarkCity;
        }
        break;

      case "postalCode":
        console.log("postal", value);
        if (!value.trim()) {
          newErrors.postalCode = "This field is required";
        } else {
          delete newErrors.postalCode;
        }
        break;

      case "referralCode":
        if (!value.trim()) {
          newErrors.referralCode = "This field is required";
        } else if (!UtilityFunc.isValidAlphaNumbericWithoutSpace(value)) {
          newErrors.referralCode = "Please enter a valid referral code";
        } else {
          delete newErrors.referralCode;
        }
        break;

      case "policyDocument":
        if (formData.isFollowGreenSustainablePolicy) {
          // Check if policyDocumentPath exists and is not an empty string
          if (!value || value.trim() === "") {
            newErrors.policyDocument = "Please upload your policy document";
          } else {
            // Additional validations based on filename
            const fileExtension = value.split(".").pop().toLowerCase();
            const allowedExtensions = ["pdf", "doc", "docx"];

            if (!allowedExtensions.includes(fileExtension)) {
              newErrors.policyDocument =
                "Only PDF, DOC, and DOCX files are allowed";
            } else {
              delete newErrors.policyDocument;
            }
          }
        } else {
          delete newErrors.policyDocument;
        }
        break;

      default:
      // No need to do anything
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateField(name, value);
  };

  const handleSelectChange = (value, field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
      ...(field === "country" && { state: null, landmarkCity: null }),
      ...(field === "state" && { landmarkCity: null }),
    }));
    validateField(field, value);

    if (field === `country`) {
      dispatch({
        type: GET_STATES,
        payload: {
          params: {
            countryId: value?.value,
          },
        },
      });

      dispatch({
        type: GET_CITIES,
        payload: {
          params: {
            countryId: value?.value,
          },
        },
      });
    } else if (field === `state`) {
      dispatch({
        type: GET_CITIES,
        payload: {
          params: {
            countryId: value?.value,
            stateId: value?.value,
          },
        },
      });
    }

    // Add your dispatch logic here for fetching dependent data
  };

  const handleMobileChange = (value, data) => {
    setFormData((prev) => ({
      ...prev,
      phoneCode: `+${data?.dialCode}`,
      mobileNumber: value.slice(data?.dialCode?.length),
    }));
    validateField("mobileNumber", value.slice(data?.dialCode?.length));
  };

  const handleBlur = (field) => {
    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));
    validateField(field, formData[field]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && UtilityFunc.fileSizeValidation(file, 2)) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "CL");
      // Add your file upload dispatch logic here
    }
  };

  const handleSubmit = () => {
    // Mark all fields as touched
    const allTouched = {
      companyName: true,
      emailAddress: true,
      mobileNumber: true,
      currency: true,
      addressLine1: true,
      country: true,
      state: true,
      city: true,
      postalCode: true,
      landmarkCity: true,
      referralCode: true,
      isFollowGreenSustainablePolicy: true,
      policyDocument: true,
    };
    setTouched(allTouched);

    // Validate all required fields
    const requiredFields = [
      "companyName",
      "emailAddress",
      "mobileNumber",
      "currency",
      "addressLine1",
      "country",
      // "state",
      "city",
      "postalCode",
      // "landmarkCity",
    ];

    let newErrors = {};

    // Validate each required field
    requiredFields.forEach((field) => {
      if (
        !formData[field] ||
        (typeof formData[field] === "string" && !formData[field].trim())
      ) {
        newErrors[field] = "This field is required";
      } else {
        // Additional field-specific validations
        switch (field) {
          case "emailAddress":
            if (!UtilityFunc.isValidEmail(formData[field])) {
              newErrors[field] = "Please enter a valid email address";
            }
            break;
          case "mobileNumber":
            if (formData[field].length < 6 || formData[field].length > 13) {
              newErrors[field] = "Should be between 6 to 13 digits";
            }
            break;
          case "city":
            if (!UtilityFunc.isValidAplha(formData[field])) {
              newErrors[field] = "Please enter a valid city name";
            }
            break;
          // case "postalCode":
          //   if (
          //     !UtilityFunc.isValidAlphaNumbericWithoutSpace(formData[field])
          //   ) {
          //     newErrors[field] = "Please enter a valid postal code";
          //   }
          //   break;

          default:
          // No Need to do anything
        }
      }
    });

    // Additional validation for policy document if green policy is checked
    if (
      formData.isFollowGreenSustainablePolicy &&
      !formData.policyDocumentPath
    ) {
      newErrors.policyDocument = "Please upload your policy document";
    }

    // Set the errors state
    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;

    if (isValid) {
      const payload = {
        name: formData.companyName,
        emailAddress: formData.emailAddress,
        phoneCode: formData.phoneCode,
        mobileNumber: formData.mobileNumber,
        currencyId: formData.currency?.value.toString(),
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        countryId: formData.country?.value,
        stateId: formData.state?.value,
        city: formData.city,
        landmarkCityId: formData.landmarkCity?.value,
        postalCode: formData.postalCode,
        usedReferralCode: formData.referralCode,
        isFollowGreenSustainablePolicy: formData.isFollowGreenSustainablePolicy,
        policyDocumentPath: formData.policyDocumentPath,
      };

      // console.log("Address Payload", getVendorAddress, payload);

      dispatch({
        type: CREATE_VENDOR_ADDRESS,
        payload: {
          data: payload,
        },
      });
    } else {
      // Scroll to the first error
      const firstErrorField = document.querySelector(".text-danger");
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  async function uploadPolicyDocument(file) {
    return await axios({
      method: "POST",
      url: `${baseUrl}/file/v1/upload`,
      headers: {
        Authorization: authToken,
      },
      data: file,
      isFormData: true,
    })
      .then(function (response) {
        return response?.data;
      })
      .catch((err) => {
        return err;
      });
  }

  const handleFileUpload = async (file) => {
    try {
      // Validate file first
      const validationResult = {
        isValid: true,
        message: "",
      };

      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["pdf", "doc", "docx"];

      // Size check
      if (file.size > 5 * 1024 * 1024) {
        validationResult.isValid = false;
        validationResult.message = "File should be less than 5MB";
      }

      // Extension check
      if (!allowedExtensions.includes(fileExtension)) {
        validationResult.isValid = false;
        validationResult.message = "Only PDF, DOC, and DOCX files are allowed";
      }

      // If validation fails
      if (!validationResult.isValid) {
        setErrors((prev) => ({
          ...prev,
          policyDocument: validationResult.message,
        }));
        return;
      }

      // Dispatch file upload action
      const uploadResult = await uploadPolicyDocument(file);

      // Update form data with uploaded file path
      setFormData((prev) => ({
        ...prev,
        policyDocumentPath: uploadResult.filePath,
      }));

      // Clear any previous errors
      setErrors((prev) => ({
        ...prev,
        policyDocument: undefined,
      }));
    } catch (error) {
      // Handle upload error
      setErrors((prev) => ({
        ...prev,
        policyDocument: error.message || "File upload failed",
      }));
    }
  };

  return (
    <TabPane tabId={tabId} className={`${activeTab !== tabId ? "d-none" : ""}`}>
      {/* <button onClick={() => console.log("formData", formData)}>
        Get current state
      </button> */}
      <Row>
        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Name
            <Tooltip name="add_com_name">Enter your company name here.</Tooltip>
          </Label>
          <TextField
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            onBlur={() => handleBlur("companyName")}
          />
          <Error
            show={touched.companyName && errors.companyName}
            message={errors.companyName}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Email
            <Tooltip name="add_com_email">
              For the record and communication purpose.
            </Tooltip>
          </Label>
          <TextField
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleChange}
            onBlur={() => handleBlur("emailAddress")}
          />
          <Error
            show={touched.emailAddress && errors.emailAddress}
            message={errors.emailAddress}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Contact No.
            <Tooltip name="add_com_contact">
              For the record and communication purpose.
            </Tooltip>
          </Label>
          <MobileField
            value={`${formData.phoneCode}${formData.mobileNumber}`}
            onChange={handleMobileChange}
            onBlur={() => handleBlur("mobileNumber")}
          />
          <Error
            show={touched.mobileNumber && errors.mobileNumber}
            message={errors.mobileNumber}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Currency in which you deal
            <Tooltip name="add_cur">
              Currency will be applicable to all the transactions you do within
              this platform.
            </Tooltip>
          </Label>
          <SelectField
            options={
              currencies?.result?.map((currencyId) => ({
                value: currencyId.id,
                label: currencyId.name,
                data: currencyId,
              })) ?? []
            }
            className="availableOnFullWidth"
            value={formData.currency}
            onChange={(data) => handleSelectChange(data, "currency")}
            onBlur={() => handleBlur("currency")}
          />
          <Error
            show={touched.currency && errors.currency}
            message={errors.currency}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Address Line 1
            <Tooltip name="add_address1">
              Enter Build No., flat no. or house no.
            </Tooltip>
          </Label>
          <TextField
            name="addressLine1"
            value={formData.addressLine1}
            onChange={handleChange}
            onBlur={() => handleBlur("addressLine1")}
          />
          <Error
            show={touched.addressLine1 && errors.addressLine1}
            message={errors.addressLine1}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label>
            Address Line 2<Tooltip name="add_address2">Enter locality.</Tooltip>
          </Label>
          <TextField
            name="addressLine2"
            value={formData.addressLine2}
            onChange={handleChange}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Country
            <Tooltip name="add_country">
              Enter country where your company is situated.
            </Tooltip>
          </Label>
          <SelectField
            options={
              countries?.result?.map((country) => ({
                value: country.id,
                label: country.name,
                data: country,
              })) ?? []
            }
            value={formData.country}
            onChange={(data) => handleSelectChange(data, "country")}
            onBlur={() => handleBlur("country")}
            // isDisabled={!formData.country}
          />
          <Error
            show={touched.country && errors.country}
            message={errors.country}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            State
            <Tooltip name="add_state">
              Enter state where your company is situated.
            </Tooltip>
          </Label>
          <SelectField
            options={
              states?.result?.map((state) => ({
                value: state.id,
                label: state.name,
                data: state,
              })) ?? []
            }
            value={formData.state}
            onChange={(data) => handleSelectChange(data, "state")}
            onBlur={() => handleBlur("state")}
            isDisabled={!formData.country}
          />
          <Error show={touched.state && errors.state} message={errors.state} />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            City
            <Tooltip name="add_city">
              Enter city where your company is situated.
            </Tooltip>
          </Label>
          <TextField
            name="city"
            value={formData.city}
            onChange={handleChange}
            onBlur={() => handleBlur("city")}
          />
          <Error show={touched.city && errors.city} message={errors.city} />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Postal Code
            <Tooltip name="add_postal_code">
              Postal code of your company location.
            </Tooltip>
          </Label>
          <NumberField
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            onBlur={() => handleBlur("postalCode")}
          />
          <Error
            show={touched.postalCode && errors.postalCode}
            message={errors.postalCode}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Land Mark City
            <Tooltip name="add_landmark">
              To identify your company's nearest city.
            </Tooltip>
          </Label>
          <SelectField
            options={
              cities?.result?.map((city) => ({
                value: city?.id,
                label: city?.name,
                data: city,
              })) ?? []
            }
            value={formData.landmarkCity}
            onChange={(data) => handleSelectChange(data, "landmarkCity")}
            onBlur={() => handleBlur("landmarkCity")}
            isDisabled={!formData.state}
          />
          <Error
            show={touched.landmarkCity && errors.landmarkCity}
            message={errors.landmarkCity}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label>
            Referral Code
            <Tooltip name="add_referral">
              Enter referral code if you have any.
            </Tooltip>
          </Label>
          <TextField
            name="referralCode"
            value={formData.referralCode}
            onChange={handleChange}
            onBlur={() => handleBlur("referralCode")}
          />
          <Error
            show={touched.referralCode && errors.referralCode}
            message={errors.referralCode}
          />
        </Col>

        <Col lg={12} className="my-2">
          <div className="d-flex align-items-start">
            <CheckboxField
              name="isFollowGreenSustainablePolicy"
              isChecked={formData?.isFollowGreenSustainablePolicy}
              handleClick={(e) => {
                const isChecked = e.target.checked;
                setFormData((prev) => ({
                  ...prev,
                  isFollowGreenSustainablePolicy: isChecked,
                  policyDocumentPath: ``,
                }));
                validateField(
                  "policyDocument",
                  isChecked ? formData.policyDocumentPath : ""
                );
              }}
            />
            <span className="ms-2">
              Our company follows Green sustainable policy and the guidelines.
            </span>
          </div>
          <Error
            show={
              touched.isFollowGreenSustainablePolicy &&
              errors.isFollowGreenSustainablePolicy
            }
            message={errors.isFollowGreenSustainablePolicy}
          />
        </Col>

        {formData.isFollowGreenSustainablePolicy && (
          <Col lg={12} className="my-2">
            <FileUploadField
              name="policyDocument"
              label="Upload Policy Document"
              accept=".pdf,.doc,.docx"
              maxSizeInMB={5}
              showRequired={true}
              onValidate={(file) => {
                const fileExtension = file.name.split(".").pop().toLowerCase();
                const allowedExtensions = ["pdf", "doc", "docx"];

                // Size check
                if (file.size > 5 * 1024 * 1024) {
                  return {
                    isValid: false,
                    message: "File should be less than 5MB",
                  };
                }

                // Extension check
                if (!allowedExtensions.includes(fileExtension)) {
                  return {
                    isValid: false,
                    message: "Only PDF, DOC, and DOCX files are allowed",
                  };
                }

                return { isValid: true };
              }}
              onChange={(file) => {
                if (file) {
                  handleFileUpload(file);
                }
              }}
              value={null}
              error={touched.policyDocument && errors.policyDocument}
              disabled={!formData.isFollowGreenSustainablePolicy}
            />
          </Col>
        )}

        <Col lg={12}>
          <div className="d-flex justify-content-end">
            <PrimaryButton name={"Save & Next"} onClick={handleSubmit} />
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default Address;

const initialFormData = {
  companyName: "",
  emailAddress: "",
  phoneCode: "+91",
  mobileNumber: "",
  currency: null,
  addressLine1: "",
  addressLine2: "",
  country: null,
  state: null,
  city: "",
  landmarkCity: null,
  postalCode: "",
  referralCode: "",
  isFollowGreenSustainablePolicy: false,
  policyDocumentPath: "",
};
