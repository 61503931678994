import { all, fork } from "redux-saga/effects";

// COMMON
import getCountriesSaga from "./common/countries/saga";
import getCategoriesSaga from "./common/categories/saga";
import getCitiesSaga from "./common/cities/saga";
import getCurrenciesSaga from "./common/currencies/saga";
import getEquipmentsSaga from "./common/equipments/saga";
import getMakesSaga from "./common/makes/saga";
import getPortsSaga from "./common/ports/saga";
import getSocialMediasSaga from "./common/socialMedias/saga";
import getStatesSaga from "./common/states/saga";
import getVesselCategoriesSaga from "./common/vesselCategories/saga";
import getPortLocationsSaga from "./common/portLocations/saga";
import getIssueTypesSaga from "./common/issueTypes/saga";
import getVesselTypesSaga from "./common/vesselTypes/saga";
import getVesselFlagsSaga from "./common/vesselFlags/saga";
import getEmailsListSaga from "./common/emailsList/saga";

// SIGNUP
import customerSignupSaga from "./signup/customerSignup/saga";
import vendorSignupSaga from "./signup/vendorSignup/saga";
import getRegistrationStatusSaga from "./registration/vendor/getRegistrationStatus/saga";

// REGISTRATION - VENDOR
import createVendorAddressSaga from "./registration/vendor/createAddress/saga";
import getVendorServicesSaga from "./registration/vendor/getService/saga";
import getVendorHeadSaga from "./registration/vendor/getHead/saga";
import getVendorFinanciersSaga from "./registration/vendor/getFinancier/saga";
import getVendorEngineersSaga from "./registration/vendor/getEngineers/saga";
import getVendorBusinessInfoSaga from "./registration/vendor/getBusinessInfo/saga";
import getVendorBiddersSaga from "./registration/vendor/getBidders/saga";
import getVendorApproversSaga from "./registration/vendor/getApprovers/saga";
import getVendorAddressSaga from "./registration/vendor/getAddress/saga";
import createVendorServiceSaga from "./registration/vendor/createService/saga";
import createVendorHeadSaga from "./registration/vendor/createHead/saga";
import createVendorFinancierSaga from "./registration/vendor/createFinancier/saga";
import createVendorEngineerSaga from "./registration/vendor/createEngineer/saga";
import CreateVendorBusinessInfoSaga from "./registration/vendor/createBusinessInfo/saga";
import createVendorBidderSaga from "./registration/vendor/createBidder/saga";
import createVendorApproverSaga from "./registration/vendor/createApprover/saga";
import createPaymentSaga from "./registration/vendor/createPayment/saga";
import getDownloadInvoiceSaga from "./registration/vendor/getDownloadInvoice/saga";
import getInvoiceDetailsSaga from "./registration/vendor/getInvoiceDetails/saga";
import sendInvoiceSaga from "./registration/vendor/sendInvoice/saga";
import getSuccessPaymentDetailsSaga from "./registration/vendor/successPaymentDetails/saga";
import markRegistrationCompletedSaga from "./registration/vendor/markRegistrationCompleted/saga";

// REGISTRATION - CUSTOMER
import createCustomerAddressSaga from "./registration/customer/createAddress/saga";
import getCustomerAddressSaga from "./registration/customer/getAddress/saga";
import getCustomerCompanyHeadSaga from "./registration/customer/getCompanyHead/saga";
import createCustomerCompanyHeadSaga from "./registration/customer/createCompanyHead/saga";
import createCustomerBusinessInfoSaga from "./registration/customer/createBusinessInfo/saga";
import getCustomerBusinessInfoSaga from "./registration/customer/getBusinessInfo/saga";
import getCustomerFinancierSaga from "./registration/customer/getFinancier/saga";
import createCustomerFinancierSaga from "./registration/customer/createFinancier/saga";
import createCustomerFleetInfoSaga from "./registration/customer/createFleetInfo/saga";
import getCustomerFleetInfoSaga from "./registration/customer/getFleetInfo/saga";
import getCustomerApproverSaga from "./registration/customer/getApprover/saga";
import createCustomerApproverSaga from "./registration/customer/createApprover/saga";
import getFailedPaymentDetailsSaga from "./registration/vendor/failedPaymentDetails/saga";

// DASHBOARD
import getDashboardDataSaga from "./dashboard/customer/requestor/getDashboardData/saga";
import createServiceRequestSaga from "./dashboard/customer/requestor/createServiceRequest/saga";
import getServiceRequestsSaga from "./dashboard/customer/requestor/getServiceRequests/saga";
import getServiceRequestByIdSaga from "./dashboard/customer/requestor/getServiceRequestById/saga";
import updateServiceRequestSaga from "./dashboard/customer/requestor/updateServiceRequest/saga";
import fileUploadSaga from "./common/fileUpload/saga";
import getUserDashboardSaga from "./common/dashboard/saga";
import LoginSaga from "./login/saga";
import getServiceRequestDetailSaga from "./dashboard/vendor/bidder/getServiceRequests/saga";
import updateServiceRequestStatusSaga from "./dashboard/vendor/bidder/updateServiceRequests/saga";
import markInterestedSaga from "./dashboard/vendor/bidder/MarkInterested/saga";
import updateBidStatusSaga from "./dashboard/vendor/bidder/UpdateBidStatus/saga";
import getBidDetailByIdSaga from "./dashboard/vendor/bidder/GetBidDetailById/saga";
import getAllBidsSaga from "./dashboard/vendor/bidder/GetAllBids/saga";
import raiseBidSaga from "./dashboard/vendor/bidder/RaiseBid/saga";
import getInterestedRequestDetailsByIdSaga from "./dashboard/vendor/bidder/GetInterestedRequestDetailsById/saga";
import getCustomerCompanyApprovalLevelSaga from "./registration/customer/getApprovalLevels/saga";
import createCustomerCompanyApprovalLevelSaga from "./registration/customer/createApprovalLevel/saga";

export default function* rootSaga() {
  // COMMON
  yield all([fork(getCategoriesSaga)]);
  yield all([fork(getCitiesSaga)]);
  yield all([fork(getCountriesSaga)]);
  yield all([fork(getCurrenciesSaga)]);
  yield all([fork(getEquipmentsSaga)]);
  yield all([fork(getMakesSaga)]);
  yield all([fork(getPortsSaga)]);
  yield all([fork(getSocialMediasSaga)]);
  yield all([fork(getStatesSaga)]);
  yield all([fork(getVesselCategoriesSaga)]);
  yield all([fork(getPortLocationsSaga)]);
  yield all([fork(getIssueTypesSaga)]);
  yield all([fork(getUserDashboardSaga)]);
  yield all([fork(fileUploadSaga)]);
  yield all([fork(getVesselTypesSaga)]);
  yield all([fork(getVesselFlagsSaga)]);
  yield all([fork(getEmailsListSaga)]);

  // SIGNUP
  yield all([fork(customerSignupSaga)]);
  yield all([fork(vendorSignupSaga)]);
  yield all([fork(getRegistrationStatusSaga)]);

  // LOGIN
  yield all([fork(LoginSaga)]);

  // REGISTRATION - VENDOR
  yield all([fork(createVendorAddressSaga)]);
  yield all([fork(getVendorServicesSaga)]);
  yield all([fork(getVendorHeadSaga)]);
  yield all([fork(getVendorFinanciersSaga)]);
  yield all([fork(getVendorEngineersSaga)]);
  yield all([fork(getVendorBusinessInfoSaga)]);
  yield all([fork(getVendorBiddersSaga)]);
  yield all([fork(getVendorApproversSaga)]);
  yield all([fork(getVendorAddressSaga)]);
  yield all([fork(createVendorServiceSaga)]);
  yield all([fork(createVendorHeadSaga)]);
  yield all([fork(createVendorEngineerSaga)]);
  yield all([fork(createVendorFinancierSaga)]);
  yield all([fork(CreateVendorBusinessInfoSaga)]);
  yield all([fork(createVendorBidderSaga)]);
  yield all([fork(createVendorApproverSaga)]);
  yield all([fork(createPaymentSaga)]);
  yield all([fork(getDownloadInvoiceSaga)]);
  yield all([fork(getInvoiceDetailsSaga)]);
  yield all([fork(sendInvoiceSaga)]);
  yield all([fork(getSuccessPaymentDetailsSaga)]);
  yield all([fork(getFailedPaymentDetailsSaga)]);
  yield all([fork(markRegistrationCompletedSaga)]);

  // REGISTRATION - CUSTOMER
  yield all([fork(getCustomerAddressSaga)]);
  yield all([fork(createCustomerAddressSaga)]);
  yield all([fork(getCustomerCompanyHeadSaga)]);
  yield all([fork(createCustomerCompanyHeadSaga)]);
  yield all([fork(getCustomerBusinessInfoSaga)]);
  yield all([fork(createCustomerBusinessInfoSaga)]);
  yield all([fork(getCustomerFinancierSaga)]);
  yield all([fork(createCustomerFinancierSaga)]);
  yield all([fork(getCustomerFleetInfoSaga)]);
  yield all([fork(createCustomerFleetInfoSaga)]);
  yield all([fork(getCustomerApproverSaga)]);
  yield all([fork(createCustomerApproverSaga)]);
  yield all([fork(getCustomerCompanyApprovalLevelSaga)]);
  yield all([fork(createCustomerCompanyApprovalLevelSaga)]);

  // DASHBOARD
  yield all([fork(getDashboardDataSaga)]);
  yield all([fork(createServiceRequestSaga)]);
  yield all([fork(getServiceRequestsSaga)]);
  yield all([fork(updateServiceRequestSaga)]);
  yield all([fork(getServiceRequestByIdSaga)]);

  // Vendor Dashboard
  yield all([fork(getServiceRequestDetailSaga)]);
  yield all([fork(updateServiceRequestStatusSaga)]);
  yield all([fork(markInterestedSaga)]);
  yield all([fork(updateBidStatusSaga)]);
  yield all([fork(getBidDetailByIdSaga)]);
  yield all([fork(getAllBidsSaga)]);
  yield all([fork(raiseBidSaga)]);
  yield all([fork(getInterestedRequestDetailsByIdSaga)]);
}
