import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_VENDOR_HEAD,
  GET_VENDOR_HEAD_SUCCESS,
  GET_VENDOR_HEAD_ERROR,
} from "../../../actionTypes.js";

function* getVendorHead() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/head/v1/get-head`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_VENDOR_HEAD_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VENDOR_HEAD_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VENDOR_HEAD_ERROR, payload: error });
  }
}

export function* watchGetVendorHead() {
  yield takeEvery(GET_VENDOR_HEAD, getVendorHead);
}

function* getVendorHeadSaga() {
  yield all([fork(watchGetVendorHead)]);
}

export default getVendorHeadSaga;
