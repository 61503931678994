export default function SimpleTextField({ ...props }) {
  return (
    <input
      type="text"
      className={props.flag ? "simpleTextFieldPlane" : "simpleTextField"}
      {...props}
      autoComplete="off"
    />
  );
}
