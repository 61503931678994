import React from "react";
import Sidebar from "./Sidebar";
import MainArea from "./MainArea";

const RegistrationLayout = ({
  children,
  sidebarMenus,
  onToggle,
  currentActiveSection,
  registrationType,
}) => {
  return (
    <section className={"registration_section"}>
      <div className={"registration_sectionContainer"}>
        <Sidebar
          sidebarMenus={sidebarMenus}
          onToggle={onToggle}
          currentActiveSection={currentActiveSection}
          registrationType={registrationType}
        />
        <MainArea>{children}</MainArea>
      </div>
    </section>
  );
};

export default RegistrationLayout;
