import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_CUSTOMER_ADDRESS,
  GET_CUSTOMER_ADDRESS_SUCCESS,
  GET_CUSTOMER_ADDRESS_ERROR,
} from "../../../actionTypes.js";

function* getCustomerAddress() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/customer/v1/get-address`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_CUSTOMER_ADDRESS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CUSTOMER_ADDRESS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CUSTOMER_ADDRESS_ERROR, payload: error });
  }
}

export function* watchGetCustomerAddress() {
  yield takeEvery(GET_CUSTOMER_ADDRESS, getCustomerAddress);
}

function* getCustomerAddressSaga() {
  yield all([fork(watchGetCustomerAddress)]);
}

export default getCustomerAddressSaga;
