import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, TabPane } from "reactstrap";
import { PrimaryButton } from "../../../../../../../components/common";
import {
  Error,
  Label,
  MobileField,
  PasswordField,
  RadioField,
  TextField,
} from "../../../../../../../components/common/FormControls";
import {
  CREATE_VENDOR_HEAD,
  CREATE_VENDOR_HEAD_EMPTY,
  GET_SOCIAL_MEDIAS,
  GET_VENDOR_HEAD,
  GET_VENDOR_HEAD_EMPTY,
} from "../../../../../../../store/apis/actionTypes";
import RegexUtils from "../../../../../../../utils/regex/regex-utils";
import ToastUtils from "../../../../../../../utils/toast/toast-utils";
import Tooltip from "../../../../../../../utils/tooltip/tooltip-utils";

const CompanyHead = ({ tabId, activeTab, toggleTab, registrationStatus }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [formData, setFormData] = useState({
    ...initialFormData,
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  /* ---------------------------- REDUX STATES ---------------------------- */
  // ADD COMPANY HEAD
  const { createVendorHead, createVendorHeadLoading } = useSelector(
    (state) => ({
      createVendorHead: state?.CreateVendorHead?.data,
      createVendorHeadLoading: state?.CreateVendorHead?.loading,
    })
  );

  // GET COMPANY HEAD
  const { getVendorHead, getVendorHeadLoading } = useSelector((state) => ({
    getVendorHead: state?.GetVendorHead?.data,
    getVendorHeadLoading: state?.GetVendorHead?.loading,
  }));

  // SOCIAL MEDIAS
  const { socialMedias, socialMediasLoading } = useSelector((state) => ({
    socialMedias: state?.SocialMedias?.data,
    socialMediasLoading: state?.SocialMedias?.loading,
  }));

  useEffect(() => {
    if (createVendorHead !== null) {
      ToastUtils.showSuccessToast("Head added successfully");
      toggleTab(3);
      dispatch({
        type: CREATE_VENDOR_HEAD_EMPTY,
      });
    }
  }, [createVendorHead]);

  useEffect(() => {
    if (registrationStatus?.isHeadAdded && tabId === activeTab) {
      dispatch({
        type: GET_VENDOR_HEAD,
      });
    } else {
      dispatch({
        type: GET_VENDOR_HEAD_EMPTY,
      });
      setFormData({ ...initialFormData });
    }
  }, [tabId, activeTab]);

  useEffect(() => {
    if (getVendorHead !== null) {
      setFormData({
        id: getVendorHead.id,
        name: getVendorHead.name,
        emailAddress: getVendorHead.emailAddress,
        phoneCode: getVendorHead.phoneCode,
        mobileNumber: getVendorHead.mobileNumber,
        password: getVendorHead.password,
        rePassword: getVendorHead.password,
        availableOn: getVendorHead.availableOn,
        isAvailableOnWhatsApp: getVendorHead.isAvailableOnWhatsApp,
      });
    }
  }, [getVendorHead]);

  useEffect(() => {
    if (socialMedias === null) {
      dispatch({
        type: GET_SOCIAL_MEDIAS,
      });
    }
  }, []);

  // Single comprehensive validation method
  const validateField = (name, value) => {
    const newErrors = { ...errors };
    const newTouched = { ...touched, [name]: true };

    // Validation logic for different fields
    switch (name) {
      case "name":
        if (!value || value.trim() === "") {
          newErrors.name = "Name is required";
        } else if (value.length < 2) {
          newErrors.name = "Name must be at least 2 characters";
        } else {
          delete newErrors.name;
        }
        break;

      case "emailAddress":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          newErrors.emailAddress = "Email Address is required";
        } else if (!emailRegex.test(value)) {
          newErrors.emailAddress = "Invalid email format";
        } else {
          delete newErrors.emailAddress;
        }
        break;

      case "mobileNumber":
        if (!value.trim()) {
          newErrors.mobileNumber = "This field is required";
        } else if (value.length < 6 || value.length > 13) {
          newErrors.mobileNumber = "Should be between 6 to 13 digits";
        } else {
          delete newErrors.mobileNumber;
        }
        break;

      case "password":
        if (!value) {
          newErrors.password = "Password is required";
        } else if (!RegexUtils.passwordRegex.test(value)) {
          newErrors.password = "Invalid password format";
        } else {
          delete newErrors.password;
        }
        break;

      case "rePassword":
        if (!value) {
          newErrors.password = "Confirm Password is required";
        } else if (!RegexUtils.passwordRegex.test(value)) {
          newErrors.password = "Invalid password format";
        } else {
          delete newErrors.password;
        }
        break;

      default:
        break;
    }

    // Update errors and touched states
    setErrors(newErrors);
    setTouched(newTouched);

    // Return whether the form is valid
    return Object.keys(newErrors).length === 0;
  };

  // Submit handler
  const handleSubmit = () => {
    // Mark all fields as touched
    const allTouched = {
      name: true,
      emailAddress: true,
      mobileNumber: true,
      password: true,
      rePassword: true,
    };
    setTouched(allTouched);

    // Validate all required fields
    const requiredFields = [
      "name",
      "emailAddress",
      "mobileNumber",
      "password",
      "rePassword",
    ];

    let newErrors = {};

    // Validate each required field
    requiredFields.forEach((field) => {
      if (
        !formData[field] ||
        (typeof formData[field] === "string" && !formData[field].trim())
      ) {
        newErrors[field] = "This field is required";
      } else {
        // Additional field-specific validations
      }
    });

    // Set the errors state
    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;

    if (isValid) {
      // Prepare payload
      const payload = {
        name: formData.name,
        emailAddress: formData.emailAddress,
        phoneCode: formData.phoneCode,
        mobileNumber: formData.mobileNumber,
        isAvailableOnWhatsApp: formData.isAvailableOnWhatsApp,
        availableOn: formData.availableOn,
        password: formData.password,
        rePassword: formData.rePassword,
      };

      console.log("submitting", payload);

      dispatch({
        type: CREATE_VENDOR_HEAD,
        payload: {
          data: payload,
        },
      });
    }
  };

  // Blur handler to mark fields as touched
  const handleBlur = (name) => {
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    // Validate the field when it loses focus
    validateField(name, formData[name]);
  };

  const handleMobileChange = (value, data) => {
    setFormData((prev) => ({
      ...prev,
      phoneCode: `+${data?.dialCode}`,
      mobileNumber: value.slice(data?.dialCode?.length),
    }));
    validateField("mobileNumber", value.slice(data?.dialCode?.length));
  };

  // Input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Validate the specific field
    validateField(name, value);
  };

  return (
    <TabPane tabId={tabId} className={`${activeTab !== tabId ? "d-none" : ""}`}>
      <button onClick={() => console.log("formData", formData)}>
        Get current state
      </button>

      <Row>
        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Name
            <Tooltip name="add_com_name">Enter your company name here.</Tooltip>
          </Label>
          <TextField
            name="name"
            value={formData.name}
            onChange={handleChange}
            onBlur={() => handleBlur("name")}
          />
          <Error show={touched.name && errors.name} message={errors.name} />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Email
            <Tooltip name="add_com_email">
              For the record and communication purpose.
            </Tooltip>
          </Label>
          <TextField
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleChange}
            onBlur={() => handleBlur("emailAddress")}
          />
          <Error
            show={touched.emailAddress && errors.emailAddress}
            message={errors.emailAddress}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Contact No.
            <Tooltip name="add_com_contact">
              For the record and communication purpose.
            </Tooltip>
          </Label>
          <MobileField
            value={`${formData.phoneCode}${formData.mobileNumber}`}
            onChange={handleMobileChange}
            onBlur={() => handleBlur("mobileNumber")}
          />
          <Error
            show={touched.mobileNumber && errors.mobileNumber}
            message={errors.mobileNumber}
          />
        </Col>

        <Col lg={12} className="my-2">
          <Label>
            I am available on Whatsapp{" "}
            <Tooltip name="available_on">
              Need information to send the latest updates.
            </Tooltip>
          </Label>
          <div className="radioContainer">
            <RadioField
              id="company-onWhatsapp-yes"
              name="isAvailableOnWhatsApp"
              value={true}
              isSelected={formData.isAvailableOnWhatsApp === true}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  isAvailableOnWhatsApp: true,
                }));

                validateField("isAvailableOnWhatsApp", true);
              }}
              label="Yes"
            />
            <RadioField
              id="company-onWhatsapp-no"
              name="isAvailableOnWhatsApp"
              value={false}
              isSelected={formData.isAvailableOnWhatsApp === false}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  isAvailableOnWhatsApp: false,
                }));

                validateField("isAvailableOnWhatsApp", false);
              }}
              label="No"
            />
          </div>
        </Col>

        {/* <Col lg={6} className="my-2">
          <Label>I am available on</Label>
          <SelectField
            options={
              socialMedias?.result?.map((currencyId) => ({
                value: currencyId.id,
                label: currencyId.name,
                data: currencyId,
              })) ?? []
            }
            className="availableOnFullWidth"
            value={formData.currency}
            // onChange={(data) => handleSelectChange(data, "currency")}
            onBlur={() => handleBlur("currency")}
          />
          <Error
            show={touched.password && errors.password}
            message={errors.password}
          />
        </Col> */}

        {/* <Col lg={6} className="my-2"></Col> */}

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Password{" "}
            <Tooltip name="head_pwd">
              The length of Password must be at least eight characters. It
              should include at least one number, at least one lowercase letter,
              at least one uppercase letter and at least one special character.
              !\"#$%&'*+,-./:;?@[]^_~
            </Tooltip>
          </Label>
          <PasswordField
            name="password"
            value={formData.password}
            onChange={handleChange}
            onBlur={() => handleBlur("password")}
          />
          <Error
            show={touched.password && errors.password}
            message={errors.password}
          />
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Re-enter Password{" "}
            <Tooltip name="head_re_pwd">Password must be matched.</Tooltip>
          </Label>
          <PasswordField
            name="rePassword"
            value={formData.rePassword}
            onChange={handleChange}
            onBlur={() => handleBlur("rePassword")}
          />
          <Error
            show={touched.rePassword && errors.rePassword}
            message={errors.rePassword}
          />
        </Col>

        {/* <Col lg={12} className="my-2">
          <div className="d-flex align-items-start">
            <CheckboxField
              name="termsAccepted"
              checked={formData.termsAccepted}
              handleClick={handleTermsChange}
              onBlur={() => handleBlur("termsAccepted")}
            />
            <span className="tcContainer ms-2">
              I agree with the
              <span
                className="termsAndConditions text-primary ms-1"
                role="button"
              >
                Terms & Conditions
              </span>
            </span>
          </div>
          {touched.termsAccepted && errors.termsAccepted && (
            <div className="text-danger small mt-1">{errors.termsAccepted}</div>
          )}
        </Col> */}

        <Col lg={12}>
          <div className="d-flex justify-content-end">
            <PrimaryButton name={"Save & Next"} onClick={handleSubmit} />
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default CompanyHead;

const initialFormData = {
  id: null,
  name: "",
  emailAddress: "",
  phoneCode: "+91",
  mobileNumber: "",
  availableOn: null,
  isAvailableOnWhatsApp: true,
  password: "",
  rePassword: "",
  isTermAndContion: false,
};
