import { combineReducers } from "redux";

// COMMON
import Countries from "./common/countries/reducer";
import Categories from "./common/categories/reducer";
import Cities from "./common/cities/reducer";
import Currencies from "./common/currencies/reducer";
import Equipments from "./common/equipments/reducer";
import Makes from "./common/makes/reducer";
import Ports from "./common/ports/reducer";
import SocialMedias from "./common/socialMedias/reducer";
import States from "./common/states/reducer";
import VesselCategories from "./common/vesselCategories/reducer";
import PortLocations from "./common/portLocations/reducer";
import IssueTypes from "./common/issueTypes/reducer";
import VesselTypes from "./common/vesselTypes/reducer";
import VesselFlags from "./common/vesselFlags/reducer";
import EmailsList from "./common/emailsList/reducer";

// SIGNUP
import VendorSignup from "./signup/vendorSignup/reducer";
import CustomerSignup from "./signup/customerSignup/reducer";
import GetRegistrationStatus from "./registration/vendor/getRegistrationStatus/reducer";

// REGISTRATION - VENDOR
import Services from "./registration/vendor/getService/reducer";
import GetVendorHead from "./registration/vendor/getHead/reducer";
import GetVendorFinanciers from "./registration/vendor/getFinancier/reducer";
import GetVendorEngineers from "./registration/vendor/getEngineers/reducer";
import GetVendorBusinessInfo from "./registration/vendor/getBusinessInfo/reducer";
import GetVendorBidders from "./registration/vendor/getBidders/reducer";
import GetVendorApprovers from "./registration/vendor/getApprovers/reducer";
import GetVendorAddress from "./registration/vendor/getAddress/reducer";
import CreateVendorFinancier from "./registration/vendor/createFinancier/reducer";
import CreateVendorEngineer from "./registration/vendor/createEngineer/reducer";
import CreateVendorBusinessInfo from "./registration/vendor/createBusinessInfo/reducer";
import CreateVendorBidder from "./registration/vendor/createBidder/reducer";
import CreateVendorApprover from "./registration/vendor/createApprover/reducer";
import CreateVendorAddress from "./registration/vendor/createAddress/reducer";
import CreateVendorService from "./registration/vendor/createService/reducer";
import CreateVendorHead from "./registration/vendor/createHead/reducer";
import CreatePayment from "./registration/vendor/createPayment/reducer";
import GetDownloadInvoice from "./registration/vendor/getDownloadInvoice/reducer";
import GetInvoiceDetails from "./registration/vendor/getInvoiceDetails/reducer";
import SendInvoice from "./registration/vendor/sendInvoice/reducer";
import GetSuccessPaymentDetails from "./registration/vendor/successPaymentDetails/reducer";
import GetFailedPaymentDetails from "./registration/vendor/failedPaymentDetails/reducer";
import MarkRegistrationCompleted from "./registration/vendor/markRegistrationCompleted/reducer";

// REGISTRATION - CUSTOMER
import GetCustomerAddress from "./registration/customer/getAddress/reducer";
import CreateCustomerAddress from "./registration/customer/createAddress/reducer";
import GetCustomerCompanyHead from "./registration/customer/getCompanyHead/reducer";
import CreateCustomerCompanyHead from "./registration/customer/createCompanyHead/reducer";
import GetCustomerBusinessInfo from "./registration/customer/getBusinessInfo/reducer";
import CreateCustomerBusinessInfo from "./registration/customer/createBusinessInfo/reducer";
import GetCustomerFinancier from "./registration/customer/getFinancier/reducer";
import CreateCustomerFinancier from "./registration/customer/createFinancier/reducer";
import GetCustomerFleetInfo from "./registration/customer/getFleetInfo/reducer";
import CreateCustomerFleetInfo from "./registration/customer/createFleetInfo/reducer";
import GetCustomerApprover from "./registration/customer/getApprover/reducer";
import CreateCustomerApprover from "./registration/customer/createApprover/reducer";
import GetCustomerCompanyApprovalLevel from "./registration/customer/getApprovalLevels/reducer";
import CreateCustomerCompanyApprovalLevel from "./registration/customer/createApprovalLevel/reducer";

// DASHBOARD
import GetDashboardData from "./dashboard/customer/requestor/getDashboardData/reducer";
import CreateServiceRequest from "./dashboard/customer/requestor/createServiceRequest/reducer";
import GetServiceRequests from "./dashboard/customer/requestor/getServiceRequests/reducer";
import GetServiceRequestById from "./dashboard/customer/requestor/getServiceRequestById/reducer";
import UpdateServiceRequest from "./dashboard/customer/requestor/updateServiceRequest/reducer";
import fileUpload from "./common/fileUpload/reducer";
import UserDashboard from "./common/dashboard/reducer";
import Login from "./login/reducer";

// Vendor Dashboard
import GetServiceRequestDetail from "./dashboard/vendor/bidder/getServiceRequests/reducer";
import UpdateServiceRequestStatus from "./dashboard/vendor/bidder/updateServiceRequests/reducer";
import MarkInterested from "./dashboard/vendor/bidder/MarkInterested/reducer";
import UpdateBidStatus from "./dashboard/vendor/bidder/UpdateBidStatus/reducer";
import GetBidDetailById from "./dashboard/vendor/bidder/GetBidDetailById/reducer";
import GetAllBids from "./dashboard/vendor/bidder/GetAllBids/reducer";
import RaisedBid from "./dashboard/vendor/bidder/RaiseBid/reducer";
import GetInterestedRequestDetailsById from "./dashboard/vendor/bidder/GetInterestedRequestDetailsById/reducer";

const rootReducer = combineReducers({
  // COMMON
  Countries,
  Categories,
  Cities,
  Currencies,
  Equipments,
  Makes,
  Ports,
  SocialMedias,
  States,
  VesselCategories,
  PortLocations,
  IssueTypes,
  fileUpload,
  UserDashboard,
  VesselTypes,
  VesselFlags,
  EmailsList,

  // SIGNUP
  VendorSignup,
  CustomerSignup,
  GetRegistrationStatus,

  //LOGIN
  Login,

  // REGISTRATION - VENDOR
  Services,
  GetVendorHead,
  GetVendorFinanciers,
  GetVendorEngineers,
  GetVendorBusinessInfo,
  GetVendorBidders,
  GetVendorApprovers,
  GetVendorAddress,
  CreateVendorService,
  CreateVendorHead,
  CreateVendorFinancier,
  CreateVendorEngineer,
  CreateVendorBusinessInfo,
  CreateVendorBidder,
  CreateVendorApprover,
  CreateVendorAddress,
  CreatePayment,
  GetDownloadInvoice,
  GetInvoiceDetails,
  SendInvoice,
  GetSuccessPaymentDetails,
  GetFailedPaymentDetails,
  MarkRegistrationCompleted,

  // REGISTRATION - CUSTOMER
  GetCustomerAddress,
  CreateCustomerAddress,
  GetCustomerCompanyHead,
  CreateCustomerCompanyHead,
  GetCustomerBusinessInfo,
  CreateCustomerBusinessInfo,
  GetCustomerFinancier,
  CreateCustomerFinancier,
  GetCustomerFleetInfo,
  CreateCustomerFleetInfo,
  GetCustomerApprover,
  CreateCustomerApprover,
  GetCustomerCompanyApprovalLevel,
  CreateCustomerCompanyApprovalLevel,

  // DASHBOARD
  GetDashboardData,
  CreateServiceRequest,
  GetServiceRequests,
  GetServiceRequestById,
  UpdateServiceRequest,

  // Vendor Dashboard
  GetServiceRequestDetail,
  UpdateServiceRequestStatus,
  MarkInterested,
  UpdateBidStatus,
  GetBidDetailById,
  GetAllBids,
  RaisedBid,
  GetInterestedRequestDetailsById,
});

export default rootReducer;
