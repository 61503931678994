import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import HttpClient from "../../../../config/http-client.js";
import { authToken, baseUrl, headers } from "../../../../config/index.js";
import {
  GET_CITIES,
  GET_CITIES_ERROR,
  GET_CITIES_SUCCESS,
} from "../../actionTypes.js";

function* getCities({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/master/v1/city/${payload?.params?.countryId}${
        payload?.params?.stateId !== undefined
          ? `/${payload?.params?.stateId}`
          : ``
      }`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_CITIES_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CITIES_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CITIES_ERROR, payload: error });
  }
}

export function* watchGetCities() {
  yield takeEvery(GET_CITIES, getCities);
}

function* getCitiesSaga() {
  yield all([fork(watchGetCities)]);
}

export default getCitiesSaga;
