import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  DashboardLoader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../../components/common";
import Error from "../../../../../components/common/FormControls/Error";
import TextValue from "../../../../../components/common/TextValue";
import DashboardLayoutWrapper from "../BidderLayout";

import SelectField from "../../../../../components/common/FormControls/SelectField";
import SimpleTextField from "../../../../../components/common/FormControls/SimpleTextField";
import { AppRoutes } from "../../../../../components/constants";
import {
  GET_BID_DETAIL_BY_ID,
  GET_BID_DETAIL_BY_ID_EMPTY,
  GET_ENGINEERS,
  RAISED_BID,
  RAISED_BID_EMPTY,
} from "../../../../../store/apis/actionTypes";
import ToastUtils from "../../../../../utils/toast/toast-utils";
import UtilityFunc from "../../../../../utils/utility-func";

const PrepareBid = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const bidId = query.get("bidId") ?? "";

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [isValidationPass, setIsValidationPass] = useState(false);

  const [isWorkingLumpsum, setIsWorkingLumpsum] = useState(true);
  const [isNotWorkingLumpsum, setIsNotWorkingLumpsum] = useState(true);

  const [isTravelLumpsum, setIsTravelLumpsum] = useState(true);
  const [isNotTravelLumpsum, setIsNotTravelLumpsum] = useState(true);

  const [isOthersLumpsum, setIsOthersLumpsum] = useState(true);
  const [isNotOthersLumpsum, setIsNotOthersLumpsum] = useState(true);
  const [isReviewState, setIsReviewState] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // BID DETAILS
  const { bidDetails, bidDetailsLoading } = useSelector((state) => ({
    bidDetails: state?.GetBidDetailById?.data,
    bidDetailsLoading: state?.GetBidDetailById?.loading,
  }));

  // ENGINEERS
  const { engineers, engineersLoading } = useSelector((state) => ({
    engineers: state?.GetVendorEngineers?.data,
    engineersLoading: state?.GetVendorEngineers?.loading,
  }));

  // BID RAISED
  const { raisedBid, raisedBidLoading } = useSelector((state) => ({
    raisedBid: state?.RaisedBid?.data,
    raisedBidLoading: state?.RaisedBid?.loading,
  }));

  console.log("bidDetails", bidDetails);

  useEffect(() => {
    dispatch({
      type: GET_BID_DETAIL_BY_ID,
      payload: {
        params: {
          bidId: bidId,
        },
      },
    });

    dispatch({
      type: GET_ENGINEERS,
    });

    return () => {
      dispatch({
        type: GET_BID_DETAIL_BY_ID_EMPTY,
      });

      dispatch({
        type: RAISED_BID_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (raisedBid !== null) {
      ToastUtils?.showSuccessToast(`Bid raised successfully!`);

      setTimeout(() => {
        dispatch({
          type: RAISED_BID_EMPTY,
        });
        navigate(AppRoutes.VENDOR_BIDDER_RAISED_BIDS);
      }, 3000);
    }
  }, [raisedBid]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedState = { ...state };

    if (!UtilityFunc.isValidNumber(value)) return;
    if (value?.length > 8) return;

    switch (name) {
      // ****************** WORKING ******************
      case "workingProposedBid-lumpSum":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...initialState?.workingProposedBid,
            lumpSum: value,
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(false);
        }
        break;

      case "normalDaysHourRate":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            normalDaysHourRate: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "normalDaysTotalHour":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            normalDaysTotalHour: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "normalDaysOt1HourRate":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            normalDaysOt1HourRate: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "normalDaysOt1TotalHour":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            normalDaysOt1TotalHour: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "normalDaysOt2HourRate":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            normalDaysOt2HourRate: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "normalDaysOt2TotalHour":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            normalDaysOt2TotalHour: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "weekendHourRate":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            weekendHourRate: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "weekendTotalHour":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            weekendTotalHour: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "weekendOt1HourRate":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            weekendOt1HourRate: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "weekendOt1TotalHour":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            weekendOt1TotalHour: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "weekendOt2HourRate":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            weekendOt2HourRate: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "weekendOt2TotalHour":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            weekendOt2TotalHour: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "othersHourRate":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            othersHourRate: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      case "othersTotalHour":
        updatedState = {
          ...state,
          workingProposedBid: {
            ...state?.workingProposedBid,
            othersTotalHour: value,
            lumpSumTotalHour: "",
            lumpSumHourRate: "",
          },
        };
        if (value === "") {
          setIsWorkingLumpsum(true);
          setIsNotWorkingLumpsum(true);
        } else {
          setIsWorkingLumpsum(false);
          setIsNotWorkingLumpsum(true);
        }
        break;

      // ****************** TRAVEL ******************
      case "lumpSum":
        updatedState = {
          ...state,
          travelProposedBid: {
            ...initialState?.travelProposedBid,
            lumpSum: value,
          },
        };
        if (value === "") {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(true);
        } else {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(false);
        }
        break;

      case "airFare":
        updatedState = {
          ...state,
          travelProposedBid: {
            ...state?.travelProposedBid,
            airFare: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(true);
        } else {
          setIsTravelLumpsum(false);
          setIsNotTravelLumpsum(true);
        }
        break;

      case "transportation1":
        updatedState = {
          ...state,
          travelProposedBid: {
            ...state?.travelProposedBid,
            transportation1: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(true);
        } else {
          setIsTravelLumpsum(false);
          setIsNotTravelLumpsum(true);
        }
        break;

      case "transportation2":
        updatedState = {
          ...state,
          travelProposedBid: {
            ...state?.travelProposedBid,
            transportation2: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(true);
        } else {
          setIsTravelLumpsum(false);
          setIsNotTravelLumpsum(true);
        }
        break;

      case "travelTime":
        updatedState = {
          ...state,
          travelProposedBid: {
            ...state?.travelProposedBid,
            travelTime: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(true);
        } else {
          setIsTravelLumpsum(false);
          setIsNotTravelLumpsum(true);
        }
        break;

      case "travelTimeOt1":
        updatedState = {
          ...state,
          travelProposedBid: {
            ...state?.travelProposedBid,
            travelTimeOt1: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(true);
        } else {
          setIsTravelLumpsum(false);
          setIsNotTravelLumpsum(true);
        }
        break;

      case "travelTimeOt2":
        updatedState = {
          ...state,
          travelProposedBid: {
            ...state?.travelProposedBid,
            travelTimeOt2: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(true);
        } else {
          setIsTravelLumpsum(false);
          setIsNotTravelLumpsum(true);
        }
        break;

      case "others":
        updatedState = {
          ...state,
          travelProposedBid: {
            ...state?.travelProposedBid,
            others: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsTravelLumpsum(true);
          setIsNotTravelLumpsum(true);
        } else {
          setIsTravelLumpsum(false);
          setIsNotTravelLumpsum(true);
        }
        break;

      // ****************** OTHERS ******************
      case "other-lumpSum":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...initialState?.otherProposedBid,
            lumpSum: value,
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(false);
        }
        break;

      case "mandatorySpares":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...state?.otherProposedBid,
            mandatorySpares: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(false);
          setIsNotOthersLumpsum(true);
        }
        break;

      case "portCharges":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...state?.otherProposedBid,
            portCharges: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(false);
          setIsNotOthersLumpsum(true);
        }
        break;

      case "covidRelatedCharge":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...state?.otherProposedBid,
            covidRelatedCharge: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(false);
          setIsNotOthersLumpsum(true);
        }
        break;

      case "shipyardCharge":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...state?.otherProposedBid,
            shipyardCharge: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(false);
          setIsNotOthersLumpsum(true);
        }
        break;

      case "accommodation":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...state?.otherProposedBid,
            accommodation: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(false);
          setIsNotOthersLumpsum(true);
        }
        break;

      case "serviceAdminFee":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...state?.otherProposedBid,
            serviceAdminFee: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(false);
          setIsNotOthersLumpsum(true);
        }
        break;

      case "otherProposedBid-others":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...state?.otherProposedBid,
            others: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(false);
          setIsNotOthersLumpsum(true);
        }
        break;

      case "miscellaneous":
        updatedState = {
          ...state,
          otherProposedBid: {
            ...state?.otherProposedBid,
            miscellaneous: value,
            lumpSum: "",
          },
        };
        if (value === "") {
          setIsOthersLumpsum(true);
          setIsNotOthersLumpsum(true);
        } else {
          setIsOthersLumpsum(false);
          setIsNotOthersLumpsum(true);
        }
        break;

      // // ****************** TAXES ******************
      // case "mandatorySpares":
      //   updatedState = {
      //     ...state,
      //     otherProposedBid: {
      //       ...state?.otherProposedBid,
      //       mandatorySpares: value,
      //     },
      //   };
      //   break;

      // ****************** ENGINEER ******************
      case "expectedHoursNeeded":
        updatedState = {
          ...state,
          expectedHoursNeeded: value,
        };
        break;

      case "noOfEngineers":
        updatedState = {
          ...state,
          noOfEngineers: value,
        };
        break;

      case "warrantyOnServiceMonths":
        updatedState = {
          ...state,
          warrantyOnServiceMonths: value,
        };
        break;

      default:
      // Noting
    }

    setState(updatedState);
  };

  const onSelectHandler = (data, label) => {
    let updatedState = { ...state };

    switch (label) {
      case "leadEngineer1Id":
        updatedState = {
          ...state,
          leadEngineer1Id: data,
        };
        break;

      case "leadEngineer2Id":
        updatedState = {
          ...state,
          leadEngineer2Id: data,
        };
        break;

      case "leadEngineer3Id":
        updatedState = {
          ...state,
          leadEngineer3Id: data,
        };
        break;

      default:
      // Nothing to do here
    }

    setState(updatedState);
  };

  const checkValidations = () => {
    let isValidToGoForward = false;

    console.log("state11", state);

    // WORKING
    if (isWorkingLumpsum) {
      if (state?.workingProposedBid?.lumpSum?.trim() !== "") {
        isValidToGoForward = true;
      } else {
        isValidToGoForward = false;
      }
    } else if (isNotWorkingLumpsum) {
      if (
        state?.workingProposedBid?.normalDaysHourRate?.trim() !== "" &&
        state?.workingProposedBid?.normalDaysTotalHour?.trim() !== "" &&
        state?.workingProposedBid?.normalDaysOt1HourRate?.trim() !== "" &&
        state?.workingProposedBid?.normalDaysOt1TotalHour?.trim() !== "" &&
        state?.workingProposedBid?.normalDaysOt2HourRate?.trim() !== "" &&
        state?.workingProposedBid?.normalDaysOt2TotalHour?.trim() !== "" &&
        state?.workingProposedBid?.weekendHourRate?.trim() !== "" &&
        state?.workingProposedBid?.weekendTotalHour?.trim() !== "" &&
        state?.workingProposedBid?.weekendOt1HourRate?.trim() !== "" &&
        state?.workingProposedBid?.weekendOt1TotalHour?.trim() !== "" &&
        state?.workingProposedBid?.weekendOt2HourRate?.trim() !== "" &&
        state?.workingProposedBid?.weekendOt2TotalHour?.trim() !== "" &&
        state?.workingProposedBid?.othersHourRate?.trim() !== "" &&
        state?.workingProposedBid?.othersTotalHour?.trim() !== ""
      ) {
        isValidToGoForward = true;
      } else {
        isValidToGoForward = false;
      }
    }

    // TRAVEL
    if (isTravelLumpsum) {
      if (state?.travelProposedBid?.lumpSum?.trim() !== "") {
        isValidToGoForward = true;
      } else {
        isValidToGoForward = false;
      }
    } else if (isNotTravelLumpsum) {
      if (
        state?.travelProposedBid?.airFare?.trim() !== "" &&
        state?.travelProposedBid?.transportation1?.trim() !== "" &&
        state?.travelProposedBid?.transportation2?.trim() !== "" &&
        state?.travelProposedBid?.travelTime?.trim() !== "" &&
        state?.travelProposedBid?.travelTimeOt1?.trim() !== "" &&
        state?.travelProposedBid?.travelTimeOt2?.trim() !== "" &&
        state?.travelProposedBid?.others?.trim() !== ""
      ) {
        isValidToGoForward = true;
      } else {
        isValidToGoForward = false;
      }
    }

    // OTHER

    // TRAVEL
    if (isOthersLumpsum) {
      if (state?.otherProposedBid?.lumpSum?.trim() !== "") {
        isValidToGoForward = true;
      } else {
        isValidToGoForward = false;
      }
    } else if (isNotOthersLumpsum) {
      if (
        state?.otherProposedBid?.mandatorySpares?.trim() !== "" &&
        state?.otherProposedBid?.portCharges?.trim() !== "" &&
        state?.otherProposedBid?.covidRelatedCharge?.trim() !== "" &&
        state?.otherProposedBid?.shipyardCharge?.trim() !== "" &&
        state?.otherProposedBid?.accommodation?.trim() !== "" &&
        state?.otherProposedBid?.serviceAdminFee?.trim() !== "" &&
        state?.otherProposedBid?.others?.trim() !== "" &&
        state?.otherProposedBid?.miscellaneous?.trim() !== ""
      ) {
        isValidToGoForward = true;
      } else {
        isValidToGoForward = false;
      }
    }

    // ENGINEER
    if (
      state?.expectedHoursNeeded?.trim() !== "" &&
      state?.noOfEngineers?.trim() !== "" &&
      state?.warrantyOnServiceMonths?.trim() !== ""
    ) {
      isValidToGoForward = true;
    } else {
      isValidToGoForward = false;
    }

    if (
      state?.leadEngineer1Id === "" &&
      state?.leadEngineer2Id === "" &&
      state?.leadEngineer3Id === ""
    ) {
      isValidToGoForward = false;
    } else {
      isValidToGoForward = true;
    }

    if (isValidToGoForward) {
      setIsReviewState(true);
      setIsValidationPass(false);
    } else {
      setIsReviewState(false);
      setIsValidationPass(true);
    }
  };

  const handleSubmit = () => {
    dispatch({
      type: RAISED_BID,
      payload: {
        data: {
          bidId: `${bidId}`,
          serviceRequestId: `${bidDetails?.serviceRequestId}`,
          workingProposedBid: {
            lumpSum: Number(`${state?.workingProposedBid?.lumpSum}`),
            normalDaysHourRate: Number(
              `${state?.workingProposedBid?.normalDaysHourRate}`
            ),
            normalDaysTotalHour: Number(
              `${state?.workingProposedBid?.normalDaysTotalHour}`
            ),
            normalDaysOt1HourRate: Number(
              `${state?.workingProposedBid?.normalDaysOt1HourRate}`
            ),
            normalDaysOt1TotalHour: Number(
              `${state?.workingProposedBid?.normalDaysOt1TotalHour}`
            ),
            normalDaysOt2HourRate: Number(
              `${state?.workingProposedBid?.normalDaysOt2HourRate}`
            ),
            normalDaysOt2TotalHour: Number(
              `${state?.workingProposedBid?.normalDaysOt2TotalHour}`
            ),
            weekendHourRate: Number(
              `${state?.workingProposedBid?.weekendHourRate}`
            ),
            weekendTotalHour: Number(
              `${state?.workingProposedBid?.weekendTotalHour}`
            ),
            weekendOt1HourRate: Number(
              `${state?.workingProposedBid?.weekendOt1HourRate}`
            ),
            weekendOt1TotalHour: Number(
              `${state?.workingProposedBid?.weekendOt1TotalHour}`
            ),
            weekendOt2HourRate: Number(
              `${state?.workingProposedBid?.weekendOt2HourRate}`
            ),
            weekendOt2TotalHour: Number(
              `${state?.workingProposedBid?.weekendOt2TotalHour}`
            ),
            othersHourRate: Number(
              `${state?.workingProposedBid?.othersHourRate}`
            ),
            othersTotalHour: Number(
              `${state?.workingProposedBid?.othersTotalHour}`
            ),
          },
          travelProposedBid: {
            lumpSum: Number(`${state?.travelProposedBid?.lumpSum}`),
            airFare: Number(`${state?.travelProposedBid?.airFare}`),
            transportation1: Number(
              `${state?.travelProposedBid?.transportation1}`
            ),
            transportation2: Number(
              `${state?.travelProposedBid?.transportation2}`
            ),
            travelTime: Number(`${state?.travelProposedBid?.travelTime}`),
            travelTimeOt1: Number(`${state?.travelProposedBid?.travelTimeOt1}`),
            travelTimeOt2: Number(`${state?.travelProposedBid?.travelTimeOt2}`),
            others: Number(`${state?.travelProposedBid?.others}`),
          },
          otherProposedBid: {
            lumpSum: Number(`${state?.otherProposedBid?.lumpSum}`),
            mandatorySpares: Number(
              `${state?.otherProposedBid?.mandatorySpares}`
            ),
            portCharges: Number(`${state?.otherProposedBid?.portCharges}`),
            covidRelatedCharge: Number(
              `${state?.otherProposedBid?.covidRelatedCharge}`
            ),
            shipyardCharge: Number(
              `${state?.otherProposedBid?.shipyardCharge}`
            ),
            accommodation: Number(`${state?.otherProposedBid?.accommodation}`),
            serviceAdminFee: Number(
              `${state?.otherProposedBid?.serviceAdminFee}`
            ),
            others: Number(`${state?.otherProposedBid?.others}`),
            miscellaneous: Number(`${state?.otherProposedBid?.miscellaneous}`),
          },
          leadEngineer1Id:
            state?.leadEngineer1Id === ""
              ? null
              : Number(`${state?.leadEngineer1Id?.value}`),
          leadEngineer2Id:
            state?.leadEngineer2Id === ""
              ? null
              : Number(`${state?.leadEngineer2Id?.value}`),
          leadEngineer3Id:
            state?.leadEngineer3Id === ""
              ? null
              : Number(`${state?.leadEngineer3Id?.value}`),
          expectedHoursNeeded: Number(`${state?.expectedHoursNeeded}`),
          noOfEngineers: Number(`${state?.noOfEngineers}`),
          warrantyOnServiceMonths: Number(`${state?.warrantyOnServiceMonths}`),
        },
      },
    });
  };

  useEffect(() => {
    const loadingStates = [
      bidDetailsLoading,
      engineersLoading,
      raisedBidLoading,
    ];
    const anyLoading = loadingStates.some((loading) => loading);
    setIsLoading(anyLoading);
  }, [bidDetailsLoading, engineersLoading, raisedBidLoading]);

  return (
    <DashboardLayoutWrapper>
      {isLoading && <DashboardLoader />}

      <div className="card request-card p-3">
        <div className="row">
          <div className="col-md-4">
            <p className="mb-1 fw-bold">Request ID:</p>
            <p className="mb-0">{bidDetails?.serviceRequestId}</p>
          </div>

          <div className="col-md-4">
            <p className="mb-1 fw-bold">Ship Name:</p>
            <p className="mb-0">{bidDetails?.vesselName}</p>
          </div>

          <div className="col-md-4">
            <p className="mb-1 fw-bold">Category:</p>
            <p className="mb-0">{bidDetails?.vesselType}</p>
          </div>
        </div>
      </div>

      {/* ************************ WORKING ************************ */}
      <div className="request-card my-4">
        <div className="prepare-bid-card-header">
          <div className="row">
            <div className="col-md-8">
              <div className="fw-bold">Description</div>
            </div>
            <div className="col-md-2">
              <div className="fw-bold">Proposed Amount</div>
            </div>
            <div className="col-md-2">
              <div className="fw-bold">Received Amount</div>
            </div>
          </div>
        </div>

        <div className="prepare-bid-card-body">
          <div className="row">
            <TextValue className="fw-semibold text-center">
              Working (A)
            </TextValue>
          </div>

          <div className="row">
            <hr />
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-5">
                  <TextValue>Lump sum</TextValue>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <SimpleTextField
                        name="workingProposedBid-lumpSum"
                        value={state?.workingProposedBid?.lumpSum}
                        disabled={!isWorkingLumpsum || isReviewState}
                        onChange={handleChange}
                        placeholder=""
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isWorkingLumpsum &&
                          state?.workingProposedBid?.lumpSum?.trim() === ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                    <div className="col-md-1"></div>

                    <div className="col-md-5"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <TextValue>{state?.workingProposedBid?.lumpSum}</TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.workingProposedBid?.lumpSum,
                  bidDetails?.workingCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row">
            <hr />
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-5">
                  <TextValue>Normal Days Normal Hours</TextValue>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <SimpleTextField
                        name="normalDaysHourRate"
                        value={state?.workingProposedBid?.normalDaysHourRate}
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        onChange={handleChange}
                        placeholder="Hourly rate"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.normalDaysHourRate?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                    <div className="col-md-1">
                      <TextValue className="text-center fw-bold">*</TextValue>
                    </div>

                    <div className="col-md-5">
                      <SimpleTextField
                        name="normalDaysTotalHour"
                        value={state?.workingProposedBid?.normalDaysTotalHour}
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        onChange={handleChange}
                        placeholder="Total hour"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.normalDaysTotalHour?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <TextValue>
                {state?.workingProposedBid?.normalDaysHourRate *
                  state?.workingProposedBid?.normalDaysTotalHour}
              </TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.workingProposedBid?.normalDaysHourRate *
                    state?.workingProposedBid?.normalDaysTotalHour,
                  bidDetails?.workingCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-5">
                  <TextValue>Normal Days OT 1</TextValue>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <SimpleTextField
                        name="normalDaysOt1HourRate"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.normalDaysOt1HourRate}
                        onChange={handleChange}
                        placeholder="Hourly rate"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.normalDaysOt1HourRate?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                    <div className="col-md-1">
                      <TextValue className="text-center fw-bold">*</TextValue>
                    </div>

                    <div className="col-md-5">
                      <SimpleTextField
                        name="normalDaysOt1TotalHour"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={
                          state?.workingProposedBid?.normalDaysOt1TotalHour
                        }
                        onChange={handleChange}
                        placeholder="Total hour"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.normalDaysOt1TotalHour?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <TextValue>
                {state?.workingProposedBid?.normalDaysOt1HourRate *
                  state?.workingProposedBid?.normalDaysOt1TotalHour}
              </TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.workingProposedBid?.normalDaysOt1HourRate *
                    state?.workingProposedBid?.normalDaysOt1TotalHour,
                  bidDetails?.workingCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-5">
                  <TextValue>Normal Days OT 2</TextValue>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <SimpleTextField
                        name="normalDaysOt2HourRate"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.normalDaysOt2HourRate}
                        onChange={handleChange}
                        placeholder="Hourly rate"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.normalDaysOt2HourRate?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                    <div className="col-md-1">
                      <TextValue className="text-center fw-bold">*</TextValue>
                    </div>

                    <div className="col-md-5">
                      <SimpleTextField
                        name="normalDaysOt2TotalHour"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={
                          state?.workingProposedBid?.normalDaysOt2TotalHour
                        }
                        onChange={handleChange}
                        placeholder="Total hour"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.normalDaysOt2TotalHour?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <TextValue>
                {state?.workingProposedBid?.normalDaysOt2HourRate *
                  state?.workingProposedBid?.normalDaysOt2TotalHour}
              </TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.workingProposedBid?.normalDaysOt2HourRate *
                    state?.workingProposedBid?.normalDaysOt2TotalHour,
                  bidDetails?.workingCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-5">
                  <TextValue>Weekend Normal Hours</TextValue>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <SimpleTextField
                        name="weekendHourRate"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.weekendHourRate}
                        onChange={handleChange}
                        placeholder="Hourly rate"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.weekendHourRate?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                    <div className="col-md-1">
                      <TextValue className="text-center fw-bold">*</TextValue>
                    </div>

                    <div className="col-md-5">
                      <SimpleTextField
                        name="weekendTotalHour"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.weekendTotalHour}
                        onChange={handleChange}
                        placeholder="Total hour"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.weekendTotalHour?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <TextValue>
                {state?.workingProposedBid?.weekendHourRate *
                  state?.workingProposedBid?.weekendTotalHour}
              </TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.workingProposedBid?.weekendHourRate *
                    state?.workingProposedBid?.weekendTotalHour,
                  bidDetails?.workingCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-5">
                  <TextValue>Weekend OT 1</TextValue>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <SimpleTextField
                        name="weekendOt1HourRate"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.weekendOt1HourRate}
                        onChange={handleChange}
                        placeholder="Hourly rate"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.weekendOt1HourRate?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                    <div className="col-md-1">
                      <TextValue className="text-center fw-bold">*</TextValue>
                    </div>

                    <div className="col-md-5">
                      <SimpleTextField
                        name="weekendOt1TotalHour"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.weekendOt1TotalHour}
                        onChange={handleChange}
                        placeholder="Total hour"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.weekendOt1TotalHour?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <TextValue>
                {state?.workingProposedBid?.weekendOt1HourRate *
                  state?.workingProposedBid?.weekendOt1TotalHour}
              </TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.workingProposedBid?.weekendOt1HourRate *
                    state?.workingProposedBid?.weekendOt1TotalHour,
                  bidDetails?.workingCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-5">
                  <TextValue>Weekend OT 2</TextValue>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <SimpleTextField
                        name="weekendOt2HourRate"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.weekendOt2HourRate}
                        onChange={handleChange}
                        placeholder="Hourly rate"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.weekendOt2HourRate?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                    <div className="col-md-1">
                      <TextValue className="text-center fw-bold">*</TextValue>
                    </div>

                    <div className="col-md-5">
                      <SimpleTextField
                        name="weekendOt2TotalHour"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.weekendOt2TotalHour}
                        onChange={handleChange}
                        placeholder="Total hour"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.weekendOt2TotalHour?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <TextValue>
                {state?.workingProposedBid?.weekendOt2HourRate *
                  state?.workingProposedBid?.weekendOt2TotalHour}
              </TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.workingProposedBid?.weekendOt2HourRate *
                    state?.workingProposedBid?.weekendOt2TotalHour,
                  bidDetails?.workingCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-5">
                  <TextValue>Others</TextValue>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-5">
                      <SimpleTextField
                        name="othersHourRate"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.othersHourRate}
                        onChange={handleChange}
                        placeholder="Hourly rate"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.othersHourRate?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                    <div className="col-md-1">
                      <TextValue className="text-center fw-bold">*</TextValue>
                    </div>

                    <div className="col-md-5">
                      <SimpleTextField
                        name="othersTotalHour"
                        disabled={!isNotWorkingLumpsum || isReviewState}
                        value={state?.workingProposedBid?.othersTotalHour}
                        onChange={handleChange}
                        placeholder="Total hour"
                      />
                      <Error
                        show={
                          isValidationPass &&
                          isNotWorkingLumpsum &&
                          state?.workingProposedBid?.othersTotalHour?.trim() ===
                            ``
                        }
                        message="Can't be empty"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <TextValue>
                {state?.workingProposedBid?.othersHourRate *
                  state?.workingProposedBid?.othersTotalHour}
              </TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.workingProposedBid?.othersHourRate *
                    state?.workingProposedBid?.othersTotalHour,
                  bidDetails?.workingCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>
        </div>
      </div>

      {/* ************************ TRAVEL ************************ */}
      <div className="request-card my-4">
        <div className="prepare-bid-card-body">
          <div className="row">
            <TextValue className="fw-semibold text-center">
              Travel (B)
            </TextValue>
          </div>

          <div className="row">
            <hr />
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Lump sum</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="lumpSum"
                disabled={!isTravelLumpsum || isReviewState}
                value={state?.travelProposedBid?.lumpSum}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isTravelLumpsum &&
                  state?.travelProposedBid?.lumpSum?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.travelProposedBid?.lumpSum,
                  bidDetails?.travelCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row">
            <hr />
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Air Fare</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="airFare"
                disabled={!isNotTravelLumpsum || isReviewState}
                value={state?.travelProposedBid?.airFare}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotTravelLumpsum &&
                  state?.travelProposedBid?.airFare?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.travelProposedBid?.airFare,
                  bidDetails?.travelCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Transportation 1</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="transportation1"
                disabled={!isNotTravelLumpsum || isReviewState}
                value={state?.travelProposedBid?.transportation1}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotTravelLumpsum &&
                  state?.travelProposedBid?.transportation1?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.travelProposedBid?.transportation1,
                  bidDetails?.travelCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Transportation 2</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="transportation2"
                disabled={!isNotTravelLumpsum || isReviewState}
                value={state?.travelProposedBid?.transportation2}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotTravelLumpsum &&
                  state?.travelProposedBid?.transportation2?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.travelProposedBid?.transportation2,
                  bidDetails?.travelCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Travel Time Normal Hours</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="travelTime"
                disabled={!isNotTravelLumpsum || isReviewState}
                value={state?.travelProposedBid?.travelTime}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotTravelLumpsum &&
                  state?.travelProposedBid?.travelTime?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.travelProposedBid?.travelTime,
                  bidDetails?.travelCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Travel Time OT1</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="travelTimeOt1"
                disabled={!isNotTravelLumpsum || isReviewState}
                value={state?.travelProposedBid?.travelTimeOt1}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotTravelLumpsum &&
                  state?.travelProposedBid?.travelTimeOt1?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.travelProposedBid?.travelTimeOt1,
                  bidDetails?.travelCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Travel Time OT2</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="travelTimeOt2"
                disabled={!isNotTravelLumpsum || isReviewState}
                value={state?.travelProposedBid?.travelTimeOt2}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotTravelLumpsum &&
                  state?.travelProposedBid?.travelTimeOt2?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.travelProposedBid?.travelTimeOt2,
                  bidDetails?.travelCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Others</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="others"
                disabled={!isNotTravelLumpsum || isReviewState}
                value={state?.travelProposedBid?.others}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotTravelLumpsum &&
                  state?.travelProposedBid?.others?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.travelProposedBid?.others,
                  bidDetails?.travelCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>
        </div>
      </div>

      {/* ************************ OTHER ************************ */}
      <div className="request-card my-4">
        <div className="prepare-bid-card-body">
          <div className="row">
            <TextValue className="fw-semibold text-center">
              Others (C)
            </TextValue>
          </div>

          <div className="row">
            <hr />
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Lump sum</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="other-lumpSum"
                disabled={!isOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.lumpSum}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isOthersLumpsum &&
                  state?.otherProposedBid?.lumpSum?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.lumpSum,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row">
            <hr />
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Mandatory Spares</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="mandatorySpares"
                disabled={!isNotOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.mandatorySpares}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotOthersLumpsum &&
                  state?.otherProposedBid?.mandatorySpares?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.mandatorySpares,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Port Charges</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="portCharges"
                disabled={!isNotOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.portCharges}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotOthersLumpsum &&
                  state?.otherProposedBid?.portCharges?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.portCharges,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Covid related Charges</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="covidRelatedCharge"
                disabled={!isNotOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.covidRelatedCharge}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotOthersLumpsum &&
                  state?.otherProposedBid?.covidRelatedCharge?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.covidRelatedCharge,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Shipyard Charges</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="shipyardCharge"
                disabled={!isNotOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.shipyardCharge}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotOthersLumpsum &&
                  state?.otherProposedBid?.shipyardCharge?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.shipyardCharge,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Accommodation</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="accommodation"
                disabled={!isNotOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.accommodation}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotOthersLumpsum &&
                  state?.otherProposedBid?.accommodation?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.accommodation,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Service Admin fee</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="serviceAdminFee"
                disabled={!isNotOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.serviceAdminFee}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotOthersLumpsum &&
                  state?.otherProposedBid?.serviceAdminFee?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.serviceAdminFee,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Others</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="otherProposedBid-others"
                disabled={!isNotOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.others}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotOthersLumpsum &&
                  state?.otherProposedBid?.others?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.others,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Miscellaneous</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="miscellaneous"
                disabled={!isNotOthersLumpsum || isReviewState}
                value={state?.otherProposedBid?.miscellaneous}
                onChange={handleChange}
              />
              <Error
                show={
                  isValidationPass &&
                  isNotOthersLumpsum &&
                  state?.otherProposedBid?.miscellaneous?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
            <div className="col-md-2">
              <TextValue>
                {calculatePercentage(
                  state?.otherProposedBid?.miscellaneous,
                  bidDetails?.otherCommissionPercentage
                )}
              </TextValue>
            </div>
          </div>
        </div>
      </div>

      {/* ************************ TAX ************************ */}
      {/* <div className="request-card my-4">
        <div className="prepare-bid-card-body">
          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Local Tax</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="vesselName"
                // disabled={isReviewState}
                // value={state?.vesselDetail?.name}
                // onChange={handleChange}
              />
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-8">
              <div className="row">
                <TextValue>Port Charges</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <TextValue>450</TextValue>
            </div>
            <div className="col-md-2">
              <TextValue>450</TextValue>
            </div>
          </div>
        </div>
      </div> */}

      {/* ************************ ENGINEERS ************************ */}
      <div className="request-card my-4">
        <div className="prepare-bid-card-body">
          <div className="row my-2">
            <div className="col-md-4">
              <div className="row">
                <TextValue>Lead engineer</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SelectField
                name="leadEngineer1Id"
                options={
                  engineers?.engineerList?.map((engineer) => ({
                    value: engineer?.id,
                    label: engineer?.name,
                    data: engineer,
                  })) ?? []
                }
                onChange={(data) => onSelectHandler(data, `leadEngineer1Id`)}
                isDisabled={isReviewState}
              />
              <Error
                show={
                  isValidationPass &&
                  state?.leadEngineer1Id === "" &&
                  state?.leadEngineer2Id === "" &&
                  state?.leadEngineer3Id === ""
                }
                message="Can't be empty"
              />
            </div>

            <div className="col-md-2">
              <SelectField
                name="leadEngineer2Id"
                options={
                  engineers?.engineerList?.map((engineer) => ({
                    value: engineer?.id,
                    label: engineer?.name,
                    data: engineer,
                  })) ?? []
                }
                onChange={(data) => onSelectHandler(data, `leadEngineer2Id`)}
                isDisabled={isReviewState}
              />
            </div>

            <div className="col-md-2">
              <SelectField
                name="leadEngineer3Id"
                options={
                  engineers?.engineerList?.map((engineer) => ({
                    value: engineer?.id,
                    label: engineer?.name,
                    data: engineer,
                  })) ?? []
                }
                onChange={(data) => onSelectHandler(data, `leadEngineer3Id`)}
                isDisabled={isReviewState}
              />
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-4">
              <div className="row">
                <TextValue>Expected hours needed:</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="expectedHoursNeeded"
                value={state?.expectedHoursNeeded}
                onChange={handleChange}
                placeholder="Hours"
                disabled={isReviewState}
              />
              <Error
                show={
                  isValidationPass && state?.expectedHoursNeeded?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-4">
              <div className="row">
                <TextValue>No Engineer(s)</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="noOfEngineers"
                value={state?.noOfEngineers}
                onChange={handleChange}
                disabled={isReviewState}
              />
              <Error
                show={isValidationPass && state?.noOfEngineers?.trim() === ``}
                message="Can't be empty"
              />
            </div>
          </div>

          <div className="row my-2">
            <div className="col-md-4">
              <div className="row">
                <TextValue>Warranty on service (months)</TextValue>
              </div>
            </div>

            <div className="col-md-2">
              <SimpleTextField
                name="warrantyOnServiceMonths"
                value={state?.warrantyOnServiceMonths}
                onChange={handleChange}
                disabled={isReviewState}
              />
              <Error
                show={
                  isValidationPass &&
                  state?.warrantyOnServiceMonths?.trim() === ``
                }
                message="Can't be empty"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-2 gap-4">
        {isReviewState && (
          <>
            <SecondaryButton
              name="Amend"
              onClick={() => setIsReviewState(false)}
            />
            <PrimaryButton name="Proceed" onClick={() => handleSubmit()} />
          </>
        )}

        {!isReviewState && (
          <>
            <PrimaryButton name="Review" onClick={() => checkValidations()} />
          </>
        )}
      </div>
    </DashboardLayoutWrapper>
  );
};

export default PrepareBid;

const initialState = {
  bidId: "",
  serviceRequestId: "",
  workingProposedBid: {
    lumpSum: "",
    normalDaysHourRate: "",
    normalDaysTotalHour: "",
    normalDaysOt1HourRate: "",
    normalDaysOt1TotalHour: "",
    normalDaysOt2HourRate: "",
    normalDaysOt2TotalHour: "",
    weekendHourRate: "",
    weekendTotalHour: "",
    weekendOt1HourRate: "",
    weekendOt1TotalHour: "",
    weekendOt2HourRate: "",
    weekendOt2TotalHour: "",
    othersHourRate: "",
    othersTotalHour: "",
  },
  travelProposedBid: {
    lumpSum: "",
    airFare: "",
    transportation1: "",
    transportation2: "",
    travelTime: "",
    travelTimeOt1: "",
    travelTimeOt2: "",
    others: "",
  },
  otherProposedBid: {
    lumpSum: "",
    mandatorySpares: "",
    portCharges: "",
    covidRelatedCharge: "",
    shipyardCharge: "",
    accommodation: "",
    serviceAdminFee: "",
    others: "",
    miscellaneous: "",
  },
  leadEngineer1Id: "",
  leadEngineer2Id: "",
  leadEngineer3Id: "",
  expectedHoursNeeded: "",
  noOfEngineers: "",
  warrantyOnServiceMonths: "",
};

function calculatePercentage(amount, percentage) {
  return (
    parseInt(amount) - (parseInt(amount) * parseInt(percentage)) / 100 || ""
  );
}
