import { useEffect, useState } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  DashboardLoader,
  PrimaryButton,
} from "../../../../../components/common";
import Heading from "../../../../../components/common/Heading";
import HorizontalLine from "../../../../../components/common/HorizontalLine";
import SuccessfullModal from "../../../../../components/common/SuccessModal";
import TextValue from "../../../../../components/common/TextValue";
import ViewContainer from "../../../../../components/common/ViewContainer";
import ViewLabel from "../../../../../components/common/ViewLabel";
import { AppRoutes } from "../../../../../components/constants";
import { BidStatuses } from "../../../../../enums/utils-enums";
import {
  GET_SERVICE_REQUEST_DETAIL,
  MARK_INTERESTED,
  MARK_INTERESTED_EMPTY,
} from "../../../../../store/apis/actionTypes";
import DateUtils from "../../../../../utils/date-utils";
import DashboardLayoutWrapper from "../BidderLayout";

const ServiceRequestDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const serviceRequestId = query.get("serviceRequestId");

  const [isUpdateServiceRequest, setIsUpdateServiceRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    requestDetails,
    serviceDetailsLoading,
    markInterested,
    markInterestedLoading,
  } = useSelector((state) => ({
    requestDetails: state?.GetServiceRequestDetail?.data,
    serviceDetailsLoading: state?.GetServiceRequestDetail?.loading,
    markInterested: state?.MarkInterested?.data,
    markInterestedLoading: state?.MarkInterested?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_SERVICE_REQUEST_DETAIL,
      payload: {
        params: {
          requestId: serviceRequestId,
        },
      },
    });
  }, [dispatch, serviceRequestId]);

  useEffect(() => {
    if (markInterested !== null) {
      setIsUpdateServiceRequest(true);

      setTimeout(() => {
        setIsUpdateServiceRequest(false);
        dispatch({
          type: MARK_INTERESTED_EMPTY,
        });
        navigate(AppRoutes.VENDOR_BIDDER_INTERESTED_REQUESTS);
      }, 3000);
    }
  }, [markInterested]);

  const updateServiceRequest = () => {
    dispatch({
      type: MARK_INTERESTED,
      payload: {
        data: {
          serviceRequestId: serviceRequestId,
          requestStatus: BidStatuses?.INTERESTED,
        },
      },
    });
  };

  useEffect(() => {
    const loadingStates = [serviceDetailsLoading, markInterestedLoading];
    const anyLoading = loadingStates.some((loading) => loading);
    setIsLoading(anyLoading);
  }, [serviceDetailsLoading, markInterestedLoading]);

  const showMsg = () => {
    return (
      <>
        <span>Thanks for showing your interest.</span> <br />
        <span>
          The service request is successfuly moved to interested Bids.{" "}
        </span>
        <span>
          Please go to interested Bids to start bidding and raise a query.
        </span>
        <br />
        <span>The time left for bidding is 40 Hrs and 11 Mins.</span>
      </>
    );
  };

  return (
    <DashboardLayoutWrapper>
      {isLoading && <DashboardLoader />}

      {isUpdateServiceRequest && (
        <SuccessfullModal
          Msg={showMsg}
          name="Sure"
          onClick={() => {
            setIsUpdateServiceRequest(false);
            navigate(AppRoutes?.VENDOR_BIDDER_NEW_REQUESTS);
          }}
        />
      )}

      <ViewContainer>
        <Heading>Vessel Details</Heading>
        <Row>
          <Col lg={4}>
            <ViewLabel>Vessel Name</ViewLabel>
            <TextValue>{requestDetails?.vesselDetail?.name || ""}</TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Vessel Type</ViewLabel>
            <TextValue>
              {requestDetails?.vesselDetail?.vesselType?.name || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>IMO Number</ViewLabel>
            <TextValue>
              {requestDetails?.vesselDetail?.imoNumber || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Vessel Flag</ViewLabel>
            <TextValue>
              {requestDetails?.vesselDetail?.vesselFlag?.name || ""}
            </TextValue>
          </Col>
        </Row>
        <HorizontalLine />

        <Heading>Service Request At</Heading>
        <Row>
          <Col lg={4}>
            <ViewLabel>Country</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.country?.name || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Port</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.port?.name || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Location</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.location?.name || ""}
            </TextValue>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <ViewLabel>E.T.A (Local Time)</ViewLabel>
            <TextValue>
              {DateUtils?.formatDateObject(
                requestDetails?.serviceRequestAt?.eta
              ) || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>E.T.D (Local Time)</ViewLabel>
            <TextValue>
              {DateUtils?.formatDateObject(
                requestDetails?.serviceRequestAt?.etd
              ) || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Service Required On (Local Time)</ViewLabel>
            <TextValue>
              {DateUtils?.formatDateObject(
                requestDetails?.serviceRequestAt?.serviceRequestOn
              ) || ""}
            </TextValue>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <ViewLabel>Bid Send To Email ID</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.bidSendToEmailAddress || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Bid Currency</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.country?.currency || ""}
            </TextValue>
          </Col>
        </Row>
        <HorizontalLine />

        <Heading>Service Request Details</Heading>
        <Row>
          <Col lg={4}>
            <ViewLabel>Equipment Category</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.equipmentCategory?.name ||
                ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Equipment Name</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.equipment?.name || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Manufacturer</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.make?.name || ""}
            </TextValue>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <ViewLabel>Model</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.model || ""}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Issue Type</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.issueType?.name || ""}
            </TextValue>
          </Col>
        </Row>
        <HorizontalLine />

        <Row>
          <Col lg={12}>
            <ViewLabel>Description of Problem / Service Required</ViewLabel>
            <TextValue>{requestDetails?.serviceDescription || ""}</TextValue>
          </Col>
        </Row>
        <HorizontalLine />

        <Heading>Attachments</Heading>
        <Row>
          {requestDetails?.files?.length > 0 ? (
            requestDetails?.files?.map((file, index) => (
              <Col
                lg={3}
                md={4}
                sm={6}
                xs={12}
                key={index}
                className="d-flex justify-content-start mb-4"
              >
                <span
                  style={{
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(file?.url, "_blank");
                  }}
                >
                  <FaRegFileAlt />
                </span>
              </Col>
            ))
          ) : (
            <TextValue>No Attachments Available</TextValue>
          )}
        </Row>

        <HorizontalLine />
        <Row>
          <Col lg={4}>
            <ViewLabel>Bid (quotes) needed in</ViewLabel>
            <TextValue>{requestDetails?.bidTime || ""}</TextValue>
          </Col>
        </Row>
      </ViewContainer>

      <div className="d-flex justify-content-end">
        <PrimaryButton
          name={"Interested"}
          onClick={updateServiceRequest}
        ></PrimaryButton>
      </div>
    </DashboardLayoutWrapper>
  );
};

export default ServiceRequestDetails;
