import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  CREATE_VENDOR_ADDRESS,
  CREATE_VENDOR_ADDRESS_SUCCESS,
  CREATE_VENDOR_ADDRESS_ERROR,
} from "../../../actionTypes.js";

function* createVendorAddress({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/vendor/v1/add-address`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_VENDOR_ADDRESS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_VENDOR_ADDRESS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_VENDOR_ADDRESS_ERROR, payload: error });
  }
}

export function* watchCreateVendorAddress() {
  yield takeEvery(CREATE_VENDOR_ADDRESS, createVendorAddress);
}

function* createVendorAddressSaga() {
  yield all([fork(watchCreateVendorAddress)]);
}

export default createVendorAddressSaga;
