import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  SEND_INVOICE,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_ERROR,
} from "../../../actionTypes.js";

function* sendInvoice({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/invoice/v1/send-invoice`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: SEND_INVOICE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: SEND_INVOICE_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: SEND_INVOICE_ERROR, payload: error });
  }
}

export function* watchSendInvoice() {
  yield takeEvery(SEND_INVOICE, sendInvoice);
}

function* sendInvoiceSaga() {
  yield all([fork(watchSendInvoice)]);
}

export default sendInvoiceSaga;
