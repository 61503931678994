import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  CREATE_CUSTOMER_COMPANY_APPROVAL_LEVELS,
  CREATE_CUSTOMER_COMPANY_APPROVAL_LEVELS_SUCCESS,
  CREATE_CUSTOMER_COMPANY_APPROVAL_LEVELS_ERROR,
} from "../../../actionTypes.js";

function* getCustomerCompanyApprovalLevel() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/customer/v1/company-level`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: CREATE_CUSTOMER_COMPANY_APPROVAL_LEVELS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_CUSTOMER_COMPANY_APPROVAL_LEVELS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_CUSTOMER_COMPANY_APPROVAL_LEVELS_ERROR,
      payload: error,
    });
  }
}

export function* watchGetCustomerCompanyApprovalLevel() {
  yield takeEvery(
    CREATE_CUSTOMER_COMPANY_APPROVAL_LEVELS,
    getCustomerCompanyApprovalLevel
  );
}

function* getCustomerCompanyApprovalLevelSaga() {
  yield all([fork(watchGetCustomerCompanyApprovalLevel)]);
}

export default getCustomerCompanyApprovalLevelSaga;
