import { useState } from "react";
import Layout from "./Layout";
import { TeamRegistration } from "./Vendor";

const Registration = () => {
  const [currentActiveSection, setCurrentActiveSection] = useState(1);

  /* ---------------------------- TOGGLE SECTION HANDLER ---------------------------- */
  const updateCurrentActiveSection = (secNumber, flag = "") => {};

  return (
    <>
      <Layout
        sidebarMenus={teamRegistrationMenus}
        onToggle={updateCurrentActiveSection}
        currentActiveSection={
          teamRegistrationMenus[currentActiveSection - 1]?.text
        }
        registrationType={`VendorTeam`}
      >
        <TeamRegistration
          currentActiveSection={currentActiveSection}
          onToggleSection={updateCurrentActiveSection}
        />
      </Layout>
    </>
  );
};

export default Registration;

const teamRegistrationMenus = [
  {
    id: 1,
    text: `General`,
    status: `Completed`,
  },
  {
    id: 2,
    text: `Team`,
    status: `Completed`,
  },
  {
    id: 3,
    text: `Services`,
    status: `InComplete`,
  },
  {
    id: 4,
    text: `Finance`,
    status: `InComplete`,
  },
  {
    id: 5,
    text: `Payment`,
    status: `InComplete`,
  },
];
