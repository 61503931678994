export default function TextField({ ...props }) {
  return (
    <input
      type="text"
      className={props.flag ? "textFieldPlane" : "textField"}
      {...props}
      autoComplete="off"
    />
  );
}
