import React from "react";

const Menus = ({ id, name, status, onToggle, currentActiveSection }) => {
  const statusColorClasses = (status) => {
    if (status === "Completed") {
      return "Completed";
    } else if (status === "InComplete") {
      return "InComplete";
    } else {
      return "";
    }
  };

  return (
    <nav
      className={`registration_navMenuContainer ${
        name === currentActiveSection ? "active" : ""
      }`}
      onClick={() => onToggle(parseInt(id))}
    >
      <div className={"registration_text"}>{name}</div>
      <div className={`registration_count ${statusColorClasses(status)}`}>
        {id}
      </div>
    </nav>
  );
};

export default Menus;
