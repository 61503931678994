import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  CREATE_APPROVER,
  CREATE_APPROVER_SUCCESS,
  CREATE_APPROVER_ERROR,
} from "../../../actionTypes.js";

function* CreateVendorApprover({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/admin/bookings`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_APPROVER_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_APPROVER_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_APPROVER_ERROR, payload: error });
  }
}

export function* watchCreateVendorApprover() {
  yield takeEvery(CREATE_APPROVER, CreateVendorApprover);
}

function* createVendorApproverSaga() {
  yield all([fork(watchCreateVendorApprover)]);
}

export default createVendorApproverSaga;
