import React from "react";

import Menus from "../Menus";
import { useSelector } from "react-redux";

const Sidebar = ({
  sidebarMenus,
  onToggle,
  currentActiveSection,
  registrationType,
}) => {
  /* ---------------------------- REDUX STATES ---------------------------- */
  const registrationStatus = useSelector((state) => state?.registrationStatus);

  const checkCompletionActivity = (id, text, status) => {
    let completionStatus = `InComplete`;

    if (registrationType === `VendorTeam`) {
      if (
        id === 1 &&
        registrationStatus?.isAddressAdded &&
        registrationStatus?.isHeadAdded &&
        registrationStatus?.isBusinessDetailsAdded
      ) {
        completionStatus = `Completed`;
      } else if (
        id === 1 &&
        !registrationStatus?.isAddressAdded &&
        !registrationStatus?.isHeadAdded &&
        !registrationStatus?.isBusinessDetailsAdded
      ) {
        completionStatus = `NotStarted`;
      } else if (
        id === 2 &&
        registrationStatus?.isBidderAdded &&
        registrationStatus?.isApproverAdded &&
        registrationStatus?.isEngineerAdded
      ) {
        completionStatus = `Completed`;
      } else if (
        id === 2 &&
        !registrationStatus?.isBidderAdded &&
        !registrationStatus?.isApproverAdded &&
        !registrationStatus?.isEngineerAdded
      ) {
        completionStatus = `NotStarted`;
      } else if (id === 3 && registrationStatus?.isServiceDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 3 && !registrationStatus?.isServiceDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 4 && registrationStatus?.isFinanceDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 4 && !registrationStatus?.isFinanceDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 5 && registrationStatus?.isPaymentDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 5 && !registrationStatus?.isPaymentDetailsAdded) {
        completionStatus = `NotStarted`;
      }
    } else if (registrationType === `VendorIndividualWithoutCompany`) {
      if (id === 1 && registrationStatus?.isPersonalDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 1 && !registrationStatus?.isPersonalDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 2 && registrationStatus?.isProfessionalDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 2 && !registrationStatus?.isProfessionalDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 3 && registrationStatus?.isServiceDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 3 && !registrationStatus?.isServiceDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 4 && registrationStatus?.isFinanceDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 4 && !registrationStatus?.isFinanceDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 5 && registrationStatus?.isPaymentDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 5 && !registrationStatus?.isPaymentDetailsAdded) {
        completionStatus = `NotStarted`;
      }
    } else if (registrationType === `VendorIndividualWithCompany`) {
      if (
        id === 1 &&
        registrationStatus?.isAddressAdded &&
        registrationStatus?.isHeadAdded &&
        registrationStatus?.isBusinessDetailsAdded
      ) {
        completionStatus = `Completed`;
      } else if (
        id === 1 &&
        !registrationStatus?.isAddressAdded &&
        !registrationStatus?.isHeadAdded &&
        !registrationStatus?.isBusinessDetailsAdded
      ) {
        completionStatus = `NotStarted`;
      } else if (id === 2 && registrationStatus?.isProfessionalDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 2 && !registrationStatus?.isProfessionalDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 3 && registrationStatus?.isServiceDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 3 && !registrationStatus?.isServiceDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 4 && registrationStatus?.isFinanceDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 4 && !registrationStatus?.isFinanceDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 5 && registrationStatus?.isPaymentDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 5 && !registrationStatus?.isPaymentDetailsAdded) {
        completionStatus = `NotStarted`;
      }
    } else if (registrationType === `CustomerTeam`) {
      if (
        id === 1 &&
        registrationStatus?.isAddressAdded &&
        registrationStatus?.isHeadAdded &&
        registrationStatus?.isBusinessDetailsAdded
      ) {
        completionStatus = `Completed`;
      } else if (
        id === 1 &&
        !registrationStatus?.isAddressAdded &&
        !registrationStatus?.isHeadAdded &&
        !registrationStatus?.isBusinessDetailsAdded
      ) {
        completionStatus = `NotStarted`;
      } else if (id === 3 && registrationStatus?.isFleetDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 3 && !registrationStatus?.isFleetDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (id === 4 && registrationStatus?.isFinanceDetailsAdded) {
        completionStatus = `Completed`;
      } else if (id === 4 && !registrationStatus?.isFinanceDetailsAdded) {
        completionStatus = `NotStarted`;
      } else if (
        id === 2 &&
        registrationStatus?.customerCompanyLevel == 3 &&
        registrationStatus?.levelUserAddedStatus?.["3"] &&
        registrationStatus?.levelUserAddedStatus?.["2"] &&
        registrationStatus?.levelUserAddedStatus?.["1"] &&
        registrationStatus?.levelUserAddedStatus?.["0"]
      ) {
        completionStatus = `Completed`;
      } else if (
        id === 2 &&
        registrationStatus?.customerCompanyLevel == 2 &&
        registrationStatus?.levelUserAddedStatus?.["2"] &&
        registrationStatus?.levelUserAddedStatus?.["1"] &&
        registrationStatus?.levelUserAddedStatus?.["0"]
      ) {
        completionStatus = `Completed`;
      } else if (
        id === 2 &&
        registrationStatus?.customerCompanyLevel == 3 &&
        !registrationStatus?.levelUserAddedStatus?.["3"] &&
        !registrationStatus?.levelUserAddedStatus?.["2"] &&
        !registrationStatus?.levelUserAddedStatus?.["1"] &&
        !registrationStatus?.levelUserAddedStatus?.["0"]
      ) {
        completionStatus = `NotStarted`;
      } else if (
        id === 2 &&
        registrationStatus?.customerCompanyLevel == 2 &&
        !registrationStatus?.levelUserAddedStatus?.["2"] &&
        !registrationStatus?.levelUserAddedStatus?.["1"] &&
        !registrationStatus?.levelUserAddedStatus?.["0"]
      ) {
        completionStatus = `NotStarted`;
      } else if (
        id === 2 &&
        registrationStatus?.customerCompanyLevel == undefined
      ) {
        completionStatus = `NotStarted`;
      }
    }

    console.log("statuuus", id, completionStatus);
    return completionStatus;
  };

  return (
    <div className="registration_sidebar">
      <div className="registration_sidebarContainer">
        {sidebarMenus?.map(({ id, text, status }) => {
          return (
            <Menus
              key={id}
              id={id}
              name={text}
              status={checkCompletionActivity(id, text, status)}
              onToggle={onToggle}
              currentActiveSection={currentActiveSection}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
