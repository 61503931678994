import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../../config/index.js";
import {
  RAISED_BID,
  RAISED_BID_SUCCESS,
  RAISED_BID_ERROR,
} from "../../../../actionTypes.js";
import ToastUtils from "../../../../../../utils/toast/toast-utils.js";

function* raiseBid({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/bid/v1/raised`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      yield put({
        type: RAISED_BID_SUCCESS,
        payload: response?.data,
      });
    } else {
      ToastUtils?.showErrorToast(`${response?.response?.data?.message}`);
      yield put({
        type: RAISED_BID_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({
      type: RAISED_BID_ERROR,
      payload: error,
    });
  }
}

export function* watchRaiseBid() {
  yield takeEvery(RAISED_BID, raiseBid);
}

function* raiseBidSaga() {
  yield all([fork(watchRaiseBid)]);
}

export default raiseBidSaga;
