import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_ERROR,
} from "../../../actionTypes.js";

function* getDownloadInvoice() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/invoice/v1/download`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: DOWNLOAD_INVOICE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: DOWNLOAD_INVOICE_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: DOWNLOAD_INVOICE_ERROR, payload: error });
  }
}

export function* watchDownloadInvoice() {
  yield takeEvery(DOWNLOAD_INVOICE, getDownloadInvoice);
}

function* getDownloadInvoiceSaga() {
  yield all([fork(watchDownloadInvoice)]);
}

export default getDownloadInvoiceSaga;
