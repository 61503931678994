import React, { useRef, useState } from "react";
import { Button } from "reactstrap";
import { FaCloudUploadAlt, FaTrash } from "react-icons/fa";

const FileUploadField = ({
  name,
  label = "Upload File",
  accept = ".pdf,.doc,.docx",
  maxSizeInMB = 5,
  onChange,
  onValidate,
  value,
  error,
  disabled = false,
  multiple = false,
  showRequired = false,
}) => {
  const fileInputRef = useRef(null);
  const [localFile, setLocalFile] = useState(null);

  const validateFile = (file) => {
    if (!file) {
      return { isValid: false, message: "No file selected" };
    }

    if (file.size > maxSizeInMB * 1024 * 1024) {
      return {
        isValid: false,
        message: `File size should not exceed ${maxSizeInMB}MB`,
      };
    }

    const acceptedTypes = accept.split(",");
    const fileExtension = "." + file.name.split(".").pop().toLowerCase();
    if (!acceptedTypes.includes(fileExtension)) {
      return {
        isValid: false,
        message: `Only ${accept} files are allowed`,
      };
    }

    if (onValidate) {
      const customValidation = onValidate(file);
      if (customValidation && !customValidation.isValid) {
        return customValidation;
      }
    }

    return { isValid: true };
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const validationResult = validateFile(file);

      if (validationResult.isValid) {
        setLocalFile(file);
        onChange(file);
      } else {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setLocalFile(null);
        onChange(null);

        alert(validationResult.message);
      }
    }
  };

  const handleRemoveFile = (e) => {
    if (e) e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setLocalFile(null);
    onChange(null);
  };

  const triggerFileInput = () => {
    if (fileInputRef.current && !disabled) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="file-upload-container">
      {label && (
        <label className="form-label">
          {label}
          {showRequired && <span className="text-danger">*</span>}
        </label>
      )}

      <input
        type="file"
        ref={fileInputRef}
        name={name}
        accept={accept}
        onChange={handleFileChange}
        style={{ display: "none" }}
        multiple={multiple}
        disabled={disabled}
      />

      <div
        className={`file-upload-wrapper ${error ? "is-invalid" : ""} ${
          disabled ? "disabled" : ""
        }`}
        onClick={triggerFileInput}
      >
        {localFile ? (
          <div className="file-preview">
            <div className="file-info">
              <span>{localFile.name}</span>
              {!disabled && (
                <Button
                  color="link"
                  className="remove-file-btn"
                  onClick={handleRemoveFile}
                >
                  <FaTrash />
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="file-upload-placeholder">
            <FaCloudUploadAlt size={40} />
            <p>Upload File</p>
            <small>
              Max size: {maxSizeInMB}MB | Accepted: {accept}
            </small>
          </div>
        )}
      </div>

      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default FileUploadField;
