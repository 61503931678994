import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_CUSTOMER_APPROVER,
  GET_CUSTOMER_APPROVER_SUCCESS,
  GET_CUSTOMER_APPROVER_ERROR,
} from "../../../actionTypes.js";

function* getCustomerApprover() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/customer/v1/get-user`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_CUSTOMER_APPROVER_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CUSTOMER_APPROVER_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CUSTOMER_APPROVER_ERROR, payload: error });
  }
}

export function* watchGetCustomerApprover() {
  yield takeEvery(GET_CUSTOMER_APPROVER, getCustomerApprover);
}

function* getCustomerApproverSaga() {
  yield all([fork(watchGetCustomerApprover)]);
}

export default getCustomerApproverSaga;
