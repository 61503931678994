import React from "react";

const NumberField = ({ ...props }) => {
  return (
    <>
      <input
        type="number"
        className={"textField"}
        {...props}
        autoComplete="off"
      />
    </>
  );
};

export default NumberField;
