import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../../config/index.js";
import {
  GET_INTERESTED_REQUEST_DETAIL_BY_ID,
  GET_INTERESTED_REQUEST_DETAIL_BY_ID_SUCCESS,
  GET_INTERESTED_REQUEST_DETAIL_BY_ID_ERROR,
} from "../../../../actionTypes.js";
import ToastUtils from "../../../../../../utils/toast/toast-utils.js";

function* getInterestedRequestDetailsById({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/service/v1/request/interested`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        ...payload?.params,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_INTERESTED_REQUEST_DETAIL_BY_ID_SUCCESS,
        payload: response?.data,
      });
    } else {
      ToastUtils?.showErrorToast(`${response?.response?.data?.message}`);
      yield put({
        type: GET_INTERESTED_REQUEST_DETAIL_BY_ID_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({
      type: GET_INTERESTED_REQUEST_DETAIL_BY_ID_ERROR,
      payload: error,
    });
  }
}

export function* watchGetInterestedRequestDetailsById() {
  yield takeEvery(
    GET_INTERESTED_REQUEST_DETAIL_BY_ID,
    getInterestedRequestDetailsById
  );
}

function* getInterestedRequestDetailsByIdSaga() {
  yield all([fork(watchGetInterestedRequestDetailsById)]);
}

export default getInterestedRequestDetailsByIdSaga;
