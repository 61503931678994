import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../config/index.js";
import {
  GET_SOCIAL_MEDIAS,
  GET_SOCIAL_MEDIAS_SUCCESS,
  GET_SOCIAL_MEDIAS_ERROR,
} from "../../actionTypes.js";

function* getSocialMedias() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/master/v1/social-media`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_SOCIAL_MEDIAS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_SOCIAL_MEDIAS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_SOCIAL_MEDIAS_ERROR, payload: error });
  }
}

export function* watchGetSocialMedias() {
  yield takeEvery(GET_SOCIAL_MEDIAS, getSocialMedias);
}

function* getSocialMediasSaga() {
  yield all([fork(watchGetSocialMedias)]);
}

export default getSocialMediasSaga;
