import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_REGISTRATION_STATUS,
  GET_REGISTRATION_STATUS_SUCCESS,
  GET_REGISTRATION_STATUS_ERROR,
} from "../../../actionTypes.js";

function* getRegistrationStatus() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/user/v1/registration-status`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_REGISTRATION_STATUS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_REGISTRATION_STATUS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_REGISTRATION_STATUS_ERROR, payload: error });
  }
}

export function* watchRegistrationStatus() {
  yield takeEvery(GET_REGISTRATION_STATUS, getRegistrationStatus);
}

function* getRegistrationStatusSaga() {
  yield all([fork(watchRegistrationStatus)]);
}

export default getRegistrationStatusSaga;
