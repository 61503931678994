import React from "react";
import { PiInfoBold } from "react-icons/pi";
import { UncontrolledTooltip } from "reactstrap";

const Tooltip = React.memo(({ children, name, placement = "top" }) => {
  return (
    <div className="tooltipContainer">
      <span
        className="tooltipIcon"
        id={name}
        aria-labelledby={`${name}-tooltip`}
      >
        <PiInfoBold />
      </span>
      <UncontrolledTooltip placement={placement} target={name}>
        {children}
      </UncontrolledTooltip>
    </div>
  );
});

export default Tooltip;
