import React from "react";

import { AiFillCloseCircle } from "react-icons/ai";

const Pills = ({
  label,
  prefix,
  activePill,
  isShowDeleteIcon,
  onPress,
  onClose,
  allPillsLen,
  currentActivePill,
}) => {
  return (
    <div
      className={`pillsWrapper ${activePill && "active"}`}
      onClick={() => onPress(parseInt(label))}
    >
      {/* {isShowDeleteIcon && activePill && allPillsLen - 1 !== currentActivePill && (
        <span className={styles.closeIcon} onClick={(e) => onClose(e, label)}>
          <AiFillCloseCircle />
        </span>
      )} */}
      {isShowDeleteIcon && activePill && allPillsLen > 1 && (
        <span className={"closeIcon"} onClick={(e) => onClose(e, label)}>
          <AiFillCloseCircle />
        </span>
      )}
      <span className={"pill"}>{`${prefix}${label}`}</span>
    </div>
  );
};

export default Pills;
