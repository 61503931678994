export default class AppRoutes {
  static APP_HOME = "/";

  // ***************** STATIC PAGES *****************
  static FAQS = `/faqs`;
  static HOME = `/home`;
  static LOGIN = `/login`;
  static ABOUT_US = `/about-us`;
  static CONTACT_US = `/contact-us`;
  static OUR_SOLUTIONS = `/our-solutions`;
  static PRIVACY_POLICY = `/privacy-policy`;
  static TERM_SERVICES = `/term-services`;
  static RULES_GUIDELINES = `/rules-guidelines`;

  // ***************** SIGNUP *****************
  static VENDOR_SIGNUP = `/signup/vendor`;
  static CUSTOMER_SIGNUP = `/signup/customer`;

  static CUSTOMER_PROFILE = `/customer/profile`;
  static VENDOR_PROFILE = `/vendor/profile`;

  // ***************** VENDOR DASHBOARDS *****************
  // BIDDER
  static VENDOR_BIDDER_BASE_PATH = `vendor/bidder`;
  static VENDOR_BIDDER_DASHBOARD = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/dashboard`;
  static VENDOR_BIDDER_ACTIVE_REQUESTS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/active-requests`;
  static VENDOR_BIDDER_NEW_REQUESTS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/new-requests`;
  static VENDOR_BIDDER_LIVE_STATUS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/live-status`;
  static VENDOR_BIDDER_SERVICE_REQUEST_DETAILS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/new-request/service-request-details`;

  static VENDOR_BIDDER_INTERESTED_REQUESTS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/interested-request`;
  static VENDOR_BIDDER_INTERESTED_SERVICE_REQUEST_DETAILS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/interested-request/service-request-details`;
  static VENDOR_BIDDER_PREPARE_BID = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/interested-request/prepare-bid`;

  static VENDOR_BIDDER_INTERESTED_BREAK_UPS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/interested-request/service-request-details/break-ups`;

  static VENDOR_BIDDER_INTERESTED_BIDS_PROPOSAL = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/interested-request/service-request-details/break-ups/bids-proposal`;
  static VENDOR_BIDDER_BIDS_IN_PROGRESS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/bids-in-progress`;
  static VENDOR_BIDDER_RAISED_BIDS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/raised-bids`;
  static VENDOR_BIDDER_PURCHASE_ORDER_RECEIVED = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/purchase-order-received`;
  static VENDOR_BIDDER_WORK_IN_PROGRESS = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/work-in-progress`;
  static VENDOR_BIDDER_RAISED_COST_DISCOUNT = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/raised-cost-discount`;
  static VENDOR_BIDDER_COMPLETED_INVOICE = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/completed-invoice`;
  static VENDOR_BIDDER_HISTORY = `/${AppRoutes.VENDOR_BIDDER_BASE_PATH}/history`;

  // ***************** CUSTOMER DASHBOARDS *****************
  // REQUESTOR
  static CUSTOMER_REQUESTOR_BASE_PATH = `customer/requestor`;
  static CUSTOMER_REQUESTOR_DASHBOARD = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/dashboard`;
  static CUSTOMER_REQUESTOR_ACTIVE_REQUESTS = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/active-requests`;
  static CUSTOMER_REQUESTOR_OPEN_REQUESTS = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/open-requests`;
  static CUSTOMER_REQUESTOR_LIVE_STATUS = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/live-status`;
  static CUSTOMER_REQUESTOR_ADD_NEW_REQUEST = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/add-new-request`;
  static CUSTOMER_REQUESTOR_RESPONSE_TO_REQUESTS = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/response-to-request`;
  static CUSTOMER_REQUESTOR_PURCHASE_ORDERS = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/purchase-order`;
  static CUSTOMER_REQUESTOR_WORK_IN_PROGRESS = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/work-in-progress`;
  static CUSTOMER_REQUESTOR_APPROVER_ADD_COST_DISCOUNT = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/approver-add-cast-discount`;
  static CUSTOMER_REQUESTOR_COMPLETED_JOBS = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/completed-jobs`;
  static CUSTOMER_REQUESTOR_INVOICE_RAISEDS = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/invoice-raised`;
  static CUSTOMER_REQUESTOR_REQUESTED_WITH_NO_RESPONSE = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/requested-with-no-response`;
  static CUSTOMER_REQUESTOR_ARCHIVE = `/${AppRoutes.CUSTOMER_REQUESTOR_BASE_PATH}/archive`;
}
