import React from "react";

import { Col, Row, TabPane } from "reactstrap";
import { Pills } from "../../../../../../../components/common";
import {
  Label,
  MobileField,
  RadioField,
  SelectField,
  TextField,
} from "../../../../../../../components/common/FormControls";
import Tooltip from "../../../../../../../utils/tooltip/tooltip-utils";
import BidderInfoModal from "./BidderInfoModal";

const Bidder = ({ tabId, activeTab, toggleTab }) => {
  return (
    <TabPane tabId={tabId} className={`${activeTab !== tabId ? "d-none" : ""}`}>
      {/* <BidderInfoModal isOpen={true} closeModal={() => alert("closing")} /> */}
      <Row>
        <div className={"pillsContainer"}>
          {[1]?.map((_, index) => {
            return (
              <Pills
                key={index}
                prefix={`B`}
                label={`${index + 1}`}
                isShowDeleteIcon={true}
                activePill={true}
                // onPress={onChangeBidderByPills}
                // onClose={onDeleteBidderByPills}
                // allPillsLen={bidders?.length}
                // currentActivePill={currentActiveBidder}
              />
            );
          })}
        </div>
      </Row>
      <Row>
        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Name
            <Tooltip name="add_com_name">Enter your company name here.</Tooltip>
          </Label>
          <TextField
            name="companyName"
            // value={formData.companyName}
            // onChange={handleChange}
            // onBlur={() => handleBlur("companyName")}
          />
          {/* <Error
            show={touched.companyName && errors.companyName}
            message={errors.companyName}
          /> */}
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Name
            <Tooltip name="add_com_name">Enter your company name here.</Tooltip>
          </Label>
          <TextField
            name="companyName"
            // value={formData.companyName}
            // onChange={handleChange}
            // onBlur={() => handleBlur("companyName")}
          />
          {/* <Error
            show={touched.companyName && errors.companyName}
            message={errors.companyName}
          /> */}
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Name
            <Tooltip name="add_com_name">Enter your company name here.</Tooltip>
          </Label>
          <TextField
            name="companyName"
            // value={formData.companyName}
            // onChange={handleChange}
            // onBlur={() => handleBlur("companyName")}
          />
          {/* <Error
            show={touched.companyName && errors.companyName}
            message={errors.companyName}
          /> */}
        </Col>

        <Col lg={6} className="my-2">
          <Label showRequired={true}>
            Company Contact No.
            <Tooltip name="add_com_contact">
              For the record and communication purpose.
            </Tooltip>
          </Label>
          <MobileField
          // value={`${formData.phoneCode}${formData.mobileNumber}`}
          // onChange={handleMobileChange}
          // onBlur={() => handleBlur("mobileNumber")}
          />
          {/* <Error
            show={touched.mobileNumber && errors.mobileNumber}
            message={errors.mobileNumber}
          /> */}
        </Col>

        <Col lg={12} className="my-2">
          <Label>
            I am available on Whatsapp{" "}
            <Tooltip name="available_on">
              Need information to send the latest updates.
            </Tooltip>
          </Label>
          <div className="radioContainer">
            <RadioField
              id="company-onWhatsapp-yes"
              name="whatsappAvailable"
              value="yes"
              // checked={formData.whatsappAvailable === "yes"}
              // onChange={(e) => handleWhatsappChange(e.target.value)}
              label="Yes"
            />
            <RadioField
              id="company-onWhatsapp-no"
              name="whatsappAvailable"
              value="no"
              // checked={formData.whatsappAvailable === "no"}
              // onChange={(e) => handleWhatsappChange(e.target.value)}
              label="No"
            />
          </div>
        </Col>

        <Col lg={12} className="my-2">
          <Label>
            I am available on Whatsapp{" "}
            <Tooltip name="available_on">
              Need information to send the latest updates.
            </Tooltip>
          </Label>
          <SelectField
            className="availableOnFullWidth"
            // value={formData.currency}
            // onChange={(data) => handleSelectChange(data, "currency")}
            // onBlur={() => handleBlur("currency")}
          />
        </Col>

        <Col lg={12} className="my-2">
          <Label>
            Bidding Limit
            <Tooltip name="available_on">
              Need information to send the latest updates.
            </Tooltip>
          </Label>
          <div>
            <SelectField
            // className={styles.availableOn}
            // options={
            //   currencies?.result?.map((currency) => ({
            //     value: currency.id,
            //     label: currency.name,
            //     data: currency,
            //   })) ?? []
            // }
            // isDisabled={state?.currency !== null}
            // value={state?.currency}
            // onChange={(data) => onSelectHandler(data, `currency`)}
            />

            <TextField
              name="companyName"
              // value={formData.companyName}
              // onChange={handleChange}
              // onBlur={() => handleBlur("companyName")}
            />
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default Bidder;
