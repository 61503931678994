import React from "react";

const RadioField = React.memo((props) => {
  const { onChange, id, name, isSelected, label, value, disabled } = props;

  return (
    <div className="radioField">
      <input
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        type="radio"
        checked={isSelected}
        disabled={disabled}
        aria-checked={isSelected}
        aria-disabled={disabled}
      />
      <label className="form_label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
});

export default RadioField;
