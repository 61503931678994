import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const MobileField = (props) => {
  return (
    <div>
      <PhoneInput
        country={""}
        value={props?.value}
        onChange={props?.onChange}
        containerStyle={{
          color: "red",
        }}
        style={{}}
        inputStyle={{
          width: "100%",
          height: "40px",
          fontSize: "16px",
          border: "none",
          borderRadius: "6px",
          boxShadow: "3px 5px 10px #00000034",
        }}
        buttonStyle={{
          background: "orange",
          backgroundColor: "#ffffff",
          border: "none",
          left: "4px",
          borderRight: "1px solid #00000017",
        }}
        dropdownStyle={{
          background: "white",
        }}
        searchStyle={{}}
        defaultCountry="it"
        preferredCountries={["it", "se"]}
        type="search"
        placeholder={""}
        autoFocus={true}
        excludeCountries={["us"]}
        disabled={props.disabled}
        className={`disableMobileField`}
        // autoComplete={autocompleteSearch ? 'on' : 'off'}
      />
    </div>
  );
};

export default MobileField;
