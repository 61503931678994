import React from "react";

const CheckboxField = ({ name, handleClick, isChecked, label }) => {
  const uniqueId = `checkbox-${name}-${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  return (
    <div className={"checkboxField"}>
      <input
        id={uniqueId}
        name={name}
        type="checkbox"
        onChange={handleClick}
        checked={isChecked}
        aria-checked={isChecked}
      />
      <label htmlFor={uniqueId} className={"checkboxLabel"}>
        <span className={"checkboxIcon"}></span>
        {label}
      </label>
    </div>
  );
};

export default CheckboxField;
