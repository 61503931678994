import React from "react";
import CreatableSelect from "react-select/creatable";

const CustomCreatableSelect = ({
  isMulti = false,
  options = [],
  value,
  onChange,
  onInputChange,
  inputValue,
  onBlur,
  placeholder = "Select an option",
  isDisabled = false,
  styles,
  components,
}) => {
  return (
    <CreatableSelect
      isMulti={isMulti}
      options={options}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      inputValue={inputValue}
      onBlur={onBlur}
      placeholder={placeholder}
      isDisabled={isDisabled}
      styles={styles}
      components={components}
    />
  );
};

export default CustomCreatableSelect;
