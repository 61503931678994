import { ModalBody } from "reactstrap";
import { ModalLayout, SecondaryButton } from "../../../../../components/common";

function PrepareBidConfirmModal({ Msg, onClick, name, size = "md" }) {
  return (
    <ModalLayout isOpen={true} size={size} centered>
      <ModalBody>
        <div className="request_placed_successful_modal_body_container">
          <h5 className="request_placed_successful_heading mt-3">{<Msg />}</h5>
        </div>

        <div className="d-flex justify-content-center">
          <SecondaryButton name="Sure" onClick={onClick} />
        </div>
      </ModalBody>
    </ModalLayout>
  );
}

export default PrepareBidConfirmModal;
