import React, { useState } from "react";
import { TabContent } from "reactstrap";

import Navigation from "./Navigation";
import Bidder from "./Bidder";
import Approver from "./Approver/index";
import Engineer from "./Engineer/index";

const Team = ({ onToggleSection, registrationStatus }) => {
  const [activeTab, setActiveTab] = useState(4);

  const toggleTabHandler = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Navigation
        activeTab={activeTab}
        toggleTab={toggleTabHandler}
        registrationStatus={registrationStatus}
      />

      <TabContent activeTab={activeTab}>
        <Bidder tabId={4} activeTab={activeTab} toggleTab={toggleTabHandler} />
        <Approver
          tabId={5}
          activeTab={activeTab}
          toggleTab={toggleTabHandler}
        />
        <Engineer
          tabId={6}
          activeTab={activeTab}
          toggleTab={toggleTabHandler}
          onToggleSection={onToggleSection}
        />
      </TabContent>
    </React.Fragment>
  );
};

export default Team;
