import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  FAILED_PAYMENT_DETAILS,
  FAILED_PAYMENT_DETAILS_SUCCESS,
  FAILED_PAYMENT_DETAILS_ERROR,
} from "../../../actionTypes.js";

function* getFailedPaymentDetails() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/payment/v1/cancel`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: FAILED_PAYMENT_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: FAILED_PAYMENT_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: FAILED_PAYMENT_DETAILS_ERROR, payload: error });
  }
}

export function* watchGetFailedPaymentDetails() {
  yield takeEvery(FAILED_PAYMENT_DETAILS, getFailedPaymentDetails);
}

function* getFailedPaymentDetailsSaga() {
  yield all([fork(watchGetFailedPaymentDetails)]);
}

export default getFailedPaymentDetailsSaga;
