import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../config/index.js";
import {
  GET_EMAILS_LIST,
  GET_EMAILS_LIST_SUCCESS,
  GET_EMAILS_LIST_ERROR,
} from "../../actionTypes.js";

function* getEmailsList() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/user/v1/email-list`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_EMAILS_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_EMAILS_LIST_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_EMAILS_LIST_ERROR, payload: error });
  }
}

export function* watchEmailsList() {
  yield takeEvery(GET_EMAILS_LIST, getEmailsList);
}

function* getEmailsListSaga() {
  yield all([fork(watchEmailsList)]);
}

export default getEmailsListSaga;
