import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  CREATE_VENDOR_BUSINESS_INFO,
  CREATE_VENDOR_BUSINESS_INFO_SUCCESS,
  CREATE_VENDOR_BUSINESS_INFO_ERROR,
} from "../../../actionTypes.js";

function* createVendorBusinessInfo({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/vendor/v1/add-business`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_VENDOR_BUSINESS_INFO_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_VENDOR_BUSINESS_INFO_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_VENDOR_BUSINESS_INFO_ERROR, payload: error });
  }
}

export function* watchCreateVendorBusinessInfo() {
  yield takeEvery(CREATE_VENDOR_BUSINESS_INFO, createVendorBusinessInfo);
}

function* CreateVendorBusinessInfoSaga() {
  yield all([fork(watchCreateVendorBusinessInfo)]);
}

export default CreateVendorBusinessInfoSaga;
