import React, { useState } from "react";
import { TabContent } from "reactstrap";

import ToastUtils from "../../../../../../utils/toast/toast-utils";
import Address from "./Address";
import BusinessInformation from "./BusinessInformation";
import CompanyHead from "./CompanyHead";
import Navigation from "./Navigation";

const General = ({ onToggleSection, registrationStatus }) => {
  const [activeTab, setActiveTab] = useState(1);

  console.log("activeee", activeTab, registrationStatus);

  const toggleTabHandler = (tab, flag) => {
    if (activeTab !== tab) {
      if (
        !registrationStatus?.isAddressAdded &&
        flag === `READY_TO_MOVE_ANOTHER_TABS`
      ) {
        setActiveTab(tab);
      } else if (!registrationStatus?.isAddressAdded) {
        setActiveTab(1);
        ToastUtils.showErrorToast("Please fill company address first.");
      } else {
        setActiveTab(tab);
      }
    }
  };

  return (
    <React.Fragment>
      <Navigation
        activeTab={activeTab}
        toggleTab={toggleTabHandler}
        registrationStatus={registrationStatus}
      />

      <TabContent activeTab={activeTab}>
        <Address
          tabId={1}
          activeTab={activeTab}
          toggleTab={toggleTabHandler}
          registrationStatus={registrationStatus}
        />
        <CompanyHead
          tabId={2}
          activeTab={activeTab}
          toggleTab={toggleTabHandler}
          registrationStatus={registrationStatus}
        />
        <BusinessInformation
          tabId={3}
          activeTab={activeTab}
          toggleTab={toggleTabHandler}
          onToggleSection={onToggleSection}
          registrationStatus={registrationStatus}
        />
      </TabContent>
    </React.Fragment>
  );
};

export default General;
