import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../config/index.js";
import {
  GET_VENDOR_ADDRESS,
  GET_VENDOR_ADDRESS_SUCCESS,
  GET_VENDOR_ADDRESS_ERROR,
} from "../../../actionTypes.js";

function* getVendorAddress() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/vendor/v1/get-address`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_VENDOR_ADDRESS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VENDOR_ADDRESS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VENDOR_ADDRESS_ERROR, payload: error });
  }
}

export function* watchGetVendorAddress() {
  yield takeEvery(GET_VENDOR_ADDRESS, getVendorAddress);
}

function* getVendorAddressSaga() {
  yield all([fork(watchGetVendorAddress)]);
}

export default getVendorAddressSaga;
