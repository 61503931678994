import { TabPane } from "reactstrap";

const Approver = ({ tabId, activeTab, toggleTab }) => {
  return (
    <TabPane tabId={tabId}>
      <div>Approver</div>
    </TabPane>
  );
};

export default Approver;
