export default function Error({ show, message }) {
  return (
    <>
      {show && (
        <label className={show ? "validationError" : "validationSuccess"}>
          {message}
        </label>
      )}
    </>
  );
}
